import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';

//styles
import styles from "./Users.module.scss";

//contexts
import { myUserDataContext } from '../../App';

//functions
import { responseHandler } from '../../functions/responseHandler';

//dependency
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

//shared
import Loading from '../../shared/Loading';
import ModalYesorNo from '../../shared/ModalYesorNo';
import NewUserModal from '../../shared/NewUserModal';
import MyPagination from "../../shared/MyPagination";
import ShowNothing from '../../shared/ShowNothing';


const Users = () => {

    const navigate = useNavigate();
    const locations = useLocation().search;
    const myQueryString = queryString.parse(locations);

    const data = useContext(myUserDataContext);
    const { userData, isLogin, setIsLogin } = data;
    const { token, user } = userData;

    const myIdProfile = user.id;



    const [AllUsers, setAllUsers] = useState([]);
    const [isLoad, setIsLoad] = useState(true);
    const [metaPages, setMetaPage] = useState({});
    const [UpdateListUsers, setUpdateListUsers] = useState(false);


      //for make queries
      let joinqueries = [];
      for(let item in myQueryString){
  
          if(item === "page") continue ;
          const temp = `${item}=${myQueryString[item]}`;
          joinqueries.push(temp);
          
      }
      joinqueries = joinqueries.join("&");


    //get all users
    useEffect(() => {


        setIsLoad(true);
        axios.get(`/user/index?page=${myQueryString.page}${joinqueries !== "" ? `&${joinqueries}` : ""}`, {

            headers: {

                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => {

                if (res.data.status) {


                    setAllUsers(res.data.data);
                    setIsLoad(false);
                    setMetaPage(res.data.meta)
                }
            })
            .catch(error => {

                responseHandler(error, "error");
            })

    }, [UpdateListUsers])


    //for delete users
    const deleteUserHandler = (id) => {


        axios
            .delete(`/user/delete/${id}`, {

                headers: {

                    'Authorization': `Bearer ${token}`
                }
            })
            .then(res => {

                responseHandler(res, "succ");
                if (res.data.status) {

                    setUpdateListUsers(!UpdateListUsers);
                    navigate("/dashboard/users")
                }
            })
            .catch(error => {

                responseHandler(error, "error");
            })




    }


    //for click on any of paginations
    const paginationHandler = (url) => {

        setIsLoad(true);
        axios.get(`/user/index?page=${url}${joinqueries !== "" ? `&${joinqueries}` : ""}`, {

            headers: {

                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => {

                if (res.data.status) {


                    setAllUsers(res.data.data);
                    setIsLoad(false);
                    setMetaPage(res.data.meta)
                }
            })
            .catch(error => {

                responseHandler(error, "error");
            })

    }

    if (AllUsers.length) {

        var length = metaPages.links.length;
    }


    const myPath = "/dashboard/users";

    return (

        !isLoad && isLogin ?

            AllUsers.length ?

                <>

                    <div className={styles.userContainer} >


                        <div className={styles.TopOfTable}>

                            <div className={styles.leftTop}>

                                <button className='btn btn-primary mb-3 ms-2 btn-sm' data-bs-toggle="modal" data-bs-target="#newUserModal"><i className="bi bi-plus-lg ms-1"></i><span>ایجاد کاربر</span></button>
                                <Link to="/dashboard/users/trash-list"><button className='btn btn-danger mb-3 btn-sm'><i className="bi bi-trash3-fill ms-1"></i><span>سطل زباله</span></button></Link>

                            </div>


                            <span className={styles.InfoCountTable}>{`نمایش ${metaPages.from} تا ${metaPages.to} از ${metaPages.total} مورد`}</span>
                            <NewUserModal token={token} setAllUsers={setAllUsers} setUpdateListUsers={setUpdateListUsers} UpdateListUsers={UpdateListUsers} />
                        </div>


                        <div className="table-responsive"  >
                            <table className="table table-bordered table-striped" >

                                <thead>

                                    <tr>

                                        <th>ردیف</th>
                                        <th>نام کاربر</th>
                                        <th>نقش</th>
                                        <th>وضعیت</th>
                                        <th>موبایل</th>
                                        <th>عملیات</th>

                                    </tr>

                                </thead>


                                <tbody>

                                    {AllUsers.map((item, index) => {

                                        const iAm = item.id === myIdProfile ? true : false;

                                        return <tr key={index} className={`${!iAm ? '' : 'table-primary'}`}>

                                            <td>{metaPages.from + index}</td>
                                            <td>{`${item.first_name} ${item.last_name}`}</td>
                                            <td>{+item.is_admin ? "ادمین" : "کاربر"}</td>
                                            <td>{+item.is_active ? <i className="bi bi-shield-fill-check" style={{ fontSize: "1.3rem", color: "#03ac13" }}></i> : <i className="bi bi-shield-fill-x" style={{ fontSize: "1.3rem", color: "#d0312d" }}></i>}</td>
                                            <td>{item.mobile}</td>
                                            <td>

                                                <Link to={!iAm ? `/dashboard/users/edite/${item.id}` : '/dashboard/profile'} style={{ color: "#fff" }}><button className="btn btn-dark btn-sm mx-1">{!iAm ? "ویرایش" : "پروفایل"}</button></Link>
                                                {!iAm && <button className='btn btn-danger btn-sm mx-1' data-bs-toggle="modal" data-bs-target={`#iam${index}`}>حذف</button>}


                                                <ModalYesorNo idModal={`iam${index}`} doBtn="حذف" text={`${item.first_name} ${item.last_name} حذف شود؟`} Click={() => deleteUserHandler(item.id)} />

                                            </td>

                                        </tr>
                                    })}


                                </tbody>


                            </table>
                        </div>


                        {length !== 3 && <MyPagination meta={metaPages} myPath={myPath} paginationHandler={paginationHandler} joinqueries={joinqueries}/>}

                    </div>



                </>

                :

                <ShowNothing text="کاربری برای نمایش وجود ندارد" />

            :

            <Loading color="#7545fb" />
    );
};

export default Users;