import React from 'react';

//styles
import styles from "./ShowNothing.module.scss";

const ShowNothing = ({text}) => {

    return (

        <>

        <div className={styles.showNothinDiv}>

            <div>

                <span>{text}</span>

            </div>

        </div>



            
        </>
    );
};

export default ShowNothing;