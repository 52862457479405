import React, { useEffect, useState, useContext } from 'react';

//styles
import styles from "./InstallLanding.module.scss";
import copyOFNewUserModal from "../../shared/NewUserModal.module.scss";

//functions
import { changeFaToEn } from '../../functions/changeFaToEn';
import { notify } from '../../functions/toasts';
import { responseHandler } from '../../functions/responseHandler';

//dependencys
import { useParams, useNavigate, json } from 'react-router-dom';
import axios from 'axios';

//contexts
import { myUserDataContext } from '../../App';

//components
import Loading from '../../shared/Loading';


const InstallLanding = () => {

    const params = useParams();
    const navigate = useNavigate();

    const [checkBtnErsal, setCheckBtnErsal] = useState(false);

    const data = useContext(myUserDataContext);
    const { userData, isLogin } = data;
    const { token } = userData;

    const [dataOfTemplate, setDataOfTemplate] = useState({});
    const [isLoad, setIsLoad] = useState(true);

    //for selecr subject for panel
    const [selectOptions, setSelectOptions] = useState({subject : [] , label: []});
    const [totalSelect, setTotalSelect] = useState({subject : "" , label :""});


    //for disable subject and label when 5040 is active
    const [disableSubjectAndLabel , setDisableSubjectAndLabel] = useState(false);

    

    const [dataForSend, setDataForSend] = useState({

        title: "",
        slug: "",
        description: "",
        parameters: {},
        template_id: params.id,

    });



    //handle for disable subject and label when 5040 is active
    //  useEffect(() => {

    //     const goal = dataForSend.parameters.HamkadehOrNot
    //     if(goal !== "") {
            
    //         setDisableSubjectAndLabel(true);
    //         setTotalSelect({subject : "" , label : ""});
        
    //     }
    //     else if(goal === "") {
            
    //         setDisableSubjectAndLabel(false);
    //         setTotalSelect({subject : "" , label : ""});

        
    //     }

    //  } , [dataForSend.parameters.HamkadehOrNot])

    


    //for get optionValue from panel
    const getSelectOptions = () => {

        setIsLoad(true);
        axios
            .get("/landing/get-landing-subjects", {

                headers: {

                    'Authorization': `Bearer ${token}`,
                }
            })
            .then(res => {

                if (res.data.status) {

                    setSelectOptions({subject : res.data.subjects , label : res.data.labels})
                    setIsLoad(false);

                } else {

                    responseHandler(res, "error");
                }

            })
            .catch(error => {

                responseHandler(error, "error");
            })
    }


    //for getData in first render for parameters of landing
    useEffect(() => {

        const getRun = () => {

            axios
                .get(`/template/show/${params.id}`, {

                    headers: {

                        'Authorization': `Bearer ${token}`,
                    }
                })
                .then(res => {

                    if (res.data.status) {

                        setDataOfTemplate(res.data.data);
                        setIsLoad(false);

                        getSelectOptions();


                    }

                })
                .catch(error => {

                    responseHandler(error, "error");
                    if (error.response?.status === 404) navigate("/notfound", { replace: true })
                })
        }

        getRun();




    }, []);




    const inputHandler = (e) => {


        const first = changeFaToEn(e.target.value);
        setDataForSend({ ...dataForSend, [e.target.name]: first })

    }


    const inputParaHandler = (e) => {

        const first = changeFaToEn(e.target.value);
        setDataForSend({ ...dataForSend, parameters: { ...dataForSend.parameters, [e.target.name]: first } })
    }

    const selectParaHandler = (e) =>{


        let first = changeFaToEn(e.target.value);
        setTotalSelect({...totalSelect , [e.target.name] : first})
    }




    if (!isLoad) {

        var { parameters } = dataOfTemplate;
        var myParameters = JSON.parse(parameters);
    }

    //for set nameParameters for keyName in object send
    useEffect(() => {

        if (!isLoad) {

            let tempKeys = {};

            for (let item of myParameters) {

                for (let paraItem of item.parameters) {

                    tempKeys[paraItem.paraName] = "";
                }
            }

            setDataForSend({ ...dataForSend, parameters: tempKeys })
        }


    }, [isLoad]);




    //for when click on Send
    const SendHandler = () => {

        setCheckBtnErsal(true);

        if (dataForSend.title === "") {

            notify("عنوان لندینگ نمی‌تواند خالی باشد", "error");
            setCheckBtnErsal(false);
            return;

        }


        if(totalSelect.label !== "" && totalSelect.subject === ""){

            notify("در صورت انتخاب برچسب باید موضوع را هم انتخاب کرده باشید" , "error" , 2000);
            setCheckBtnErsal(false);
            return;

        }

        if (totalSelect.subject !== "" && dataForSend.parameters.type === "") {

            notify("اگر موضوع انتخاب کرده باشید باید تایپ هم داشته باشید", "error" , 2000);
            setCheckBtnErsal(false);
            return;

        }



        if (totalSelect.subject !== "" && dataForSend.parameters.token === "") {

            notify("اگر موضوع انتخاب کرده باشید باید توکن هم داشته باشید", "error" , 2000);
            setCheckBtnErsal(false);
            return;

        }


      



        const formData = new FormData();


        formData.append('title', dataForSend.title);
        formData.append('slug', dataForSend.slug);
        formData.append('description', dataForSend.description);
        formData.append('parameters', JSON.stringify({ ...dataForSend.parameters, baseAndMainTitle: dataForSend.title, subjectSelect: totalSelect.subject , labelSelect : totalSelect.label }));
        formData.append('template_id', dataForSend.template_id);
        formData.append('user_id', userData.user.id);




        

        //for send select subject to panel

        if (totalSelect.subject !== "") {

            const bodyPanel = {

                landing_token: dataForSend.parameters.token,
                type: dataForSend.parameters.type,
                subject: totalSelect.subject,
                ...(totalSelect.label !== "" && {label: totalSelect.label }),
            }

           

            axios
                .post(`/landing/send-landing-subjects`, bodyPanel, {

                    headers: {

                        'Authorization': `Bearer ${token}`,
                    }
                })
                .then(res => {

                    if (res.data.status) {

                        //for install
                        axios
                            .post("/landing/create", formData, {

                                headers: {

                                    'Authorization': `Bearer ${token}`,
                                }
                            })
                            .then(res => {

                                responseHandler(res, "succ");
                                if (res.data.status) {

                                    navigate("/dashboard/showLandings");
                                }
                                setCheckBtnErsal(false);
                            })
                            .catch(error => {

                                responseHandler(error, "error");
                                setCheckBtnErsal(false)
                            })
                    }
                    else{

                        notify("توکن وب سرویس وارد شده اشتباه است", "error");
                        setCheckBtnErsal(false)

                    }
                })
                .catch(error => {

                    responseHandler(error , "error");
                })
        }
        else{

            //for install
            axios
            .post("/landing/create", formData, {

                headers: {

                    'Authorization': `Bearer ${token}`,
                }
            })
            .then(res => {

                responseHandler(res, "succ");
                if (res.data.status) {

                    navigate("/dashboard/showLandings");
                }
                setCheckBtnErsal(false);
            })
            .catch(error => {

                responseHandler(error, "error");
                setCheckBtnErsal(false)
            })
        }





    }




    return (

        !isLoad && isLogin ?
            <>

                <div className={styles.InstallLandingDiv}>


                    <div className={styles.title}>

                        <span>{`ایجاد لندینگ از  ${dataOfTemplate.title}`}</span>
                        <i className="bi bi-front"></i>

                    </div>

                    <div className={styles.consts}>


                        <div className={copyOFNewUserModal.Inputs}>
                            <label htmlFor='titleLanding'>عنوان لندینگ:</label>
                            <input type='text' id="titleLanding" name="title" value={dataForSend.title} onChange={inputHandler} />
                        </div>


                        <div className={copyOFNewUserModal.Inputs}>
                            <label htmlFor='slugLanding'>آدرس لندینگ:</label>
                            <input type='text' id="slugLanding" name="slug" value={dataForSend.slug} onChange={inputHandler} />
                        </div>


                    </div>


                    <div className={styles.titleTopAccordion}>

                        <span>پارامتر ها</span>

                    </div>

                    <div className="accordion " id="accordionExample">

                        {myParameters.map((item, index) => {

                            return <React.Fragment key={item.groupId}>

                                <div className="accordion-item">

                                    <h2 className="accordion-header">

                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${item.groupId}`} aria-expanded="true" aria-controls="collapseOne">
                                            {item.groupName}
                                        </button>

                                    </h2>


                                    <div id={`collapse${item.groupId}`} className={`accordion-collapse collapse ${index + 1 === myParameters.length && 'show'}`} >

                                        <div className={`accordion-body ${styles.bodyOfAccordion}`}>


                                            {item.parameters.map((paraItem, paraIndex) => {

                                                return <div key={`${item.groupId}${paraItem.paraId}`}>


                                                    <div className={styles.ParaDiv}>

                                                        <div className={styles.toptext}>

                                                            <label className={styles.ParaName} htmlFor={`${item.groupId}${paraItem.paraId}`}>{paraItem.paraName}</label>
                                                            <span className={styles.paraDec}>{paraItem.paraDescription}</span>

                                                        </div>

                                                        {paraItem.paraType !== "select" ?

                                                            <input type={paraItem.paraType} id={`${item.groupId}${paraItem.paraId}`} name={paraItem.paraName} value={dataForSend.parameters[paraItem.paraName]} onChange={inputParaHandler} />

                                                            :

                                                            <select disabled={disableSubjectAndLabel} className={`${styles.SelectPanel} form-select`} name={paraItem.paraName} value={totalSelect[`${paraItem.paraName}`]} onChange={selectParaHandler}>
                                                                

                                                                <option value=""  >انتخاب نشده</option>
                                                                {selectOptions[`${paraItem.paraName}`].map((itemOp, indexOp) => {

                                                                    return <option key={indexOp} value={itemOp.id} >{itemOp.name}</option>

                                                                })}


                                                            </select>

                                                        }


                                                    </div>


                                                </div>

                                            })}







                                        </div>

                                    </div>

                                </div>



                            </React.Fragment>

                        })}

                    </div>


                    <div className={`${styles.btns} mt-5 mb-4`}>

                        <button className='btn btn-primary ms-2' disabled={checkBtnErsal} onClick={SendHandler} >
                            ایجاد لندینگ
                            {checkBtnErsal && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
                        </button>
                        <button className='btn btn-secondary' onClick={() => navigate(-1)}>بازگشت</button>
                    </div>



                </div>

            </>

            :
            <Loading color="#7545fb" />
    );
};

export default InstallLanding;