import React, { useEffect, useContext, useState } from 'react';


//styles
import styles from "./EditeLanding.module.scss";
import copyOfInstallLanding from "./InstallLanding.module.scss";
import copyOFNewUserModal from "../../shared/NewUserModal.module.scss";

//components
import Loading from '../../shared/Loading';

//dependencys
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import axios from 'axios';

//contexts
import { myUserDataContext } from '../../App';

//functions
import { responseHandler } from '../../functions/responseHandler';
import { changeFaToEn } from '../../functions/changeFaToEn';
import { notify } from '../../functions/toasts';



const EditeLanding = () => {

    const navigate = useNavigate();
    const params = useParams();

    const data = useContext(myUserDataContext);
    const { userData, isLogin , mainUrl } = data;
    const { token } = userData;

    //a copy of landing Data in database
    const [copyOfData, setCopyOfData] = useState({});

    //for get template data for redner
    const [dataOfTemplate, setDataOfTemplate] = useState([]);

    //for selecr subject for panel
    const [selectOptions, setSelectOptions] = useState({ subject: [], label: [] });

    const [checkBtnEdite, setCheckBtnEdite] = useState(false);
    const [editeLanding, setEditeLanding] = useStateWithCallbackLazy({});
    const [isLoad, setIsLoad] = useState(true);

    const [keyupdateLanding, setKeyupdateLanding] = useState(false);

    //for get optionValue from panel
    const getSelectOptions = () => {

        setIsLoad(true);
        axios
            .get("/landing/get-landing-subjects", {

                headers: {

                    'Authorization': `Bearer ${token}`,
                }
            })
            .then(res => {

                if (res.data.status) {

                    setSelectOptions({ subject: res.data.subjects, label: res.data.labels })
                    setIsLoad(false);

                } else {

                    responseHandler(res, "error");
                }

            })
            .catch(error => {

                responseHandler(error, "error");
            })
    }


    useEffect(() => {


        axios
            .get(`/landing/show/${params.id}`, {

                headers: {

                    'Authorization': `Bearer ${token}`,
                }
            })
            .then(async res => {

                responseHandler(res, "succ", false);
                if (res.data.status) {

                    const { title, slug, parameters } = res.data.data;

                    setEditeLanding({

                        title: title,
                        slug: slug,
                        parameters: JSON.parse(parameters)

                    })

                    setCopyOfData({

                        title: title,
                        slug: slug,
                        parameters: JSON.parse(parameters)

                    });


                    await axios
                        .get(`/template/show/${res.data.data.template_id}`, {

                            headers: {

                                'Authorization': `Bearer ${token}`,
                            }
                        })
                        .then(res2 => {

                            if (res2.data.status) {

                                setDataOfTemplate([...JSON.parse(res2.data.data.parameters)]);
                                setIsLoad(false);
                            }

                        })
                        .catch(error2 => {

                            responseHandler(error2, "error");

                        })

                    getSelectOptions();



                }
            })
            .catch(error => {

                responseHandler(error, "error");
                if (error.response?.status === 404) navigate("/notfound", { replace: true })
            })


    }, [keyupdateLanding])


    const inputHandler = (e) => {


        if (e.target.name === "title") {

            const firstTitle = changeFaToEn(e.target.value);
            setEditeLanding({ ...editeLanding, title: firstTitle, parameters: { ...editeLanding.parameters, baseAndMainTitle: firstTitle } })
            return;


        }

        const first = changeFaToEn(e.target.value);
        setEditeLanding({ ...editeLanding, [e.target.name]: first })

    }


    const inputParaHandler = (e) => {

        let first = changeFaToEn(e.target.value);
        setEditeLanding({ ...editeLanding, parameters: { ...editeLanding.parameters, [e.target.name]: first } })
    }









    //for compate two object
    const compareObjects = (a, b) => {

        return JSON.stringify(a) === JSON.stringify(b);
    };





    //fot click on edite btn
    const editeLandingHandler = () => {

        setCheckBtnEdite(true);

        let bodySend = {};

        for (let item in copyOfData) {

            if (item === "parameters") {

                if (compareObjects(copyOfData[item], editeLanding[item]) === false) bodySend[item] = editeLanding[item];
            }
            else {

                if (copyOfData[item] !== editeLanding[item]) bodySend[item] = editeLanding[item];
            }


        }



        const keysBodySend = Object.keys(bodySend);
        if (keysBodySend.length === 0) {

            notify("هیچ موردی را برای ویرایش تغییر نداید", "error", 2000);
            setCheckBtnEdite(false);
            return;

        }

        if (bodySend.title === "") {

            notify("عنوان لندینگ نمی‌تواند خالی باشد", "error");
            setCheckBtnEdite(false);
            return;

        }

        if (bodySend.slug === "") {

            notify("آدرس لندینگ نمی‌تواند خالی باشد", "error");
            setCheckBtnEdite(false);
            return;

        }


        if (editeLanding.parameters.labelSelect !== undefined && editeLanding.parameters.subjectSelect !== undefined) {


            if (editeLanding.parameters.labelSelect !== "" && editeLanding.parameters.subjectSelect === "") {

                notify("در صورت انتخاب برچسب باید موضوع را هم انتخاب کرده باشید", "error", 2000);
                setCheckBtnEdite(false);
                return;

            }
        }

        if (editeLanding.parameters.subjectSelect !== undefined) {

            if (editeLanding.parameters.subjectSelect !== "" && editeLanding.parameters.type === "") {

                notify("اگر موضوع انتخاب کرده باشید باید تایپ هم داشته باشید", "error", 2000);
                console.log(editeLanding.parameters.subjectSelect)
                setCheckBtnEdite(false);
                return;

            }


            if (editeLanding.parameters.subjectSelect !== "" && editeLanding.parameters.token === "") {

                notify("اگر موضوع انتخاب کرده باشید باید توکن هم داشته باشید", "error", 2000);
                setCheckBtnEdite(false);
                return;

            }
        }





        const _method = "put";
        const formData = new FormData();

        for (let item in bodySend) {

            if (item === "parameters") formData.append(`${item}`, JSON.stringify({ ...bodySend[item] }));
            else formData.append(`${item}`, bodySend[item]);

        }

        formData.append('_method', _method);




        // for send select subject to panel

        if (editeLanding.parameters.subjectSelect !== "" && editeLanding.parameters.subjectSelect !== undefined) {

            const bodyPanel = {

                landing_token: editeLanding.parameters.token,
                type: editeLanding.parameters.type,
                subject: editeLanding.parameters.subjectSelect,
                ...(editeLanding.parameters.labelSelect !== "" && { label: editeLanding.parameters.labelSelect }),
            }



            axios
                .post(`/landing/send-landing-subjects`, bodyPanel, {

                    headers: {

                        'Authorization': `Bearer ${token}`,
                    }
                })
                .then(res => {

                    if (res.data.status) {

                        //for edite
                        axios
                            .post(`/landing/update/${params.id}`, formData, {

                                headers: {

                                    'Authorization': `Bearer ${token}`,
                                }
                            })
                            .then(res => {


                                responseHandler(res, "succ");
                                if (res.data.status) {

                                    setIsLoad(true);
                                    setKeyupdateLanding(!keyupdateLanding)
                                }
                                setCheckBtnEdite(false);
                            })
                            .catch(error => {

                                responseHandler(error, "error");
                                setCheckBtnEdite(false);
                            })
                    } else {

                        notify("توکن وب سرویس وارد شده اشتباه است", "error");
                        setCheckBtnEdite(false);

                    }
                })
                .catch(error => {

                    responseHandler(error, "error")
                    setCheckBtnEdite(false);

                })
        }
        else {

            axios
                .post(`/landing/update/${params.id}`, formData, {

                    headers: {

                        'Authorization': `Bearer ${token}`,
                    }
                })
                .then(res => {


                    responseHandler(res, "succ");
                    if (res.data.status) {

                        setIsLoad(true);
                        setKeyupdateLanding(!keyupdateLanding)
                    }
                    setCheckBtnEdite(false);
                })
                .catch(error => {

                    responseHandler(error, "error");
                    setCheckBtnEdite(false);
                })

        }

    }





    return (
        !isLoad && isLogin ?
            <>

                <div className={styles.EditeLanding}>


                    <div className={copyOfInstallLanding.title}>

                        <span>{`ویرایش لندینگ   ${copyOfData.title}`}</span>
                        <i className="bi bi-gear-wide"></i>

                    </div>

                    <div className={copyOfInstallLanding.consts}>


                        <div className={copyOFNewUserModal.Inputs}>
                            <label htmlFor='EditetitleLanding'>عنوان لندینگ:</label>
                            <input type='text' id="EditetitleLanding" name="title" value={editeLanding.title} onChange={inputHandler} />
                        </div>


                        <div className={copyOFNewUserModal.Inputs}>
                            <label htmlFor='EditeslugLanding'>آدرس لندینگ:</label>
                            <input type='text' id="EditeslugLanding" name="slug" value={editeLanding.slug} onChange={inputHandler} />
                        </div>


                    </div>


                    <div className={copyOfInstallLanding.titleTopAccordion}>

                        <span>پارامتر ها</span>

                    </div>

                    <div className="accordion " id="accordionExample">

                        {dataOfTemplate.map((item, index) => {

                            return <React.Fragment key={item.groupId}>

                                <div className="accordion-item">

                                    <h2 className="accordion-header">

                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${item.groupId}`} aria-expanded="true" aria-controls="collapseOne">
                                            {item.groupName}
                                        </button>

                                    </h2>


                                    <div id={`collapse${item.groupId}`} className={`accordion-collapse collapse ${index + 1 === dataOfTemplate.length && 'show'}`} >

                                        <div className={`accordion-body ${copyOfInstallLanding.bodyOfAccordion}`}>


                                            {item.parameters.map((paraItem, paraIndex) => {

                                                return <div key={`${item.groupId}${paraItem.paraId}`}>


                                                    <div className={copyOfInstallLanding.ParaDiv}>

                                                        <div className={copyOfInstallLanding.toptext}>

                                                            <label className={copyOfInstallLanding.ParaName} htmlFor={`${item.groupId}${paraItem.paraId}`}>{paraItem.paraName}</label>
                                                            <span className={copyOfInstallLanding.paraDec}>{paraItem.paraDescription}</span>

                                                        </div>


                                                        {paraItem.paraType !== "select" ?

                                                            <input type={paraItem.paraType} id={`${item.groupId}${paraItem.paraId}`} name={paraItem.paraName} value={editeLanding.parameters[paraItem.paraName]} onChange={inputParaHandler} />

                                                            :

                                                            <select className={`${styles.SelectPanel} form-select`} name={`${paraItem.paraName}Select`} value={editeLanding.parameters[`${paraItem.paraName}Select`]} onChange={inputParaHandler}>


                                                                <option value="" >انتخاب نشده</option>
                                                                {selectOptions[`${paraItem.paraName}`].map((itemOp, indexOp) => {

                                                                    return <option key={indexOp} value={itemOp.id} >{itemOp.name}</option>

                                                                })}


                                                            </select>

                                                        }


                                                    </div>


                                                </div>

                                            })}







                                        </div>

                                    </div>

                                </div>



                            </React.Fragment>

                        })}

                    </div>


                    <div className={`${copyOfInstallLanding.btns} mt-5 mb-4 `}>

                        <button className='btn btn-primary ms-2' disabled={checkBtnEdite} onClick={editeLandingHandler} >
                            ویرایش لندینگ
                            {checkBtnEdite && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
                        </button>
                        <Link to={`${mainUrl}${editeLanding.slug}`} target='_blank' className='btn btn-warning'>مشاهده لندینگ</Link>
                        <button className='btn btn-secondary me-2' onClick={() => navigate("/dashboard/showLandings")}>بازگشت</button>
                    </div>


                </div>

            </>

            :

            <Loading color="#7545fb" />

    );
};

export default EditeLanding;