import React from 'react';

const Loading = ({color}) => {

    return (

        <>

            <div className={`d-flex flex-column justify-content-center align-items-center m-4 `} style={{ height: "300px" , color : `${color}` }}>
                <div className="spinner-border" role="status">

                </div>
                <div className='mt-2' style={{ fontSize: "0.8rem", userSelect: "none" }}>دریافت اطلاعات</div>
            </div>

        </>
    );
};

export default Loading;