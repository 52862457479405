import React, { useState, useEffect, useContext } from 'react';

//styles
import styles from "./EditeUser.module.scss";
import copyStyleNewUser from "../../shared/NewUserModal.module.scss";

//context
import { myUserDataContext } from '../../App';

//functions
import { responseHandler } from '../../functions/responseHandler';
import { changeFaToEn } from '../../functions/changeFaToEn';
import { notify } from '../../functions/toasts';
import { Select } from 'antd';
import 'react-toastify/dist/ReactToastify.css';

//dependency
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';


//components
import Loading from '../../shared/Loading';

const EditeUser = () => {

    const params = useParams();
    const navigate = useNavigate();
    const { Option } = Select;

    const data = useContext(myUserDataContext);
    const { userData, UpdateLocalStorage, setUpdateLocalStorage, isLogin } = data;
    const { token } = userData;

    //a copy of userData in database
    const [copyOfData, setCopyOfData] = useState({});

    const [checkBtnEdite, setCheckBtnEdite] = useState(false);
    const [editeUserInfo, setEditeUserInfo] = useState({});
    const [isLoad, setIsLoad] = useState(true);

    const [updateInfo, setUpdateInfo] = useState(false);
    const [optionsRoles, setOptionsRoles] = useState([]);



    //for get data of user for Edite
    useEffect(() => {

        const runGet = async () => {

            await axios
                .get(`/user/show/${params.id}?relations[]=roles`, {

                    headers: {

                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(res => {

                    
                    if (res.data.status) {

                        const { first_name, last_name, mobile, is_admin, gender, is_active, roles } = res.data.data;

                        setEditeUserInfo({

                            first_name: first_name,
                            last_name: last_name,
                            mobile: mobile,
                            password: "",
                            is_admin: +is_admin,
                            gender: gender,
                            is_active: +is_active,
                            role_id: roles[0]?.id,

                        })

                        setCopyOfData({

                            first_name: first_name,
                            last_name: last_name,
                            mobile: mobile,
                            password: "",
                            is_admin: +is_admin,
                            gender: gender,
                            is_active: +is_active,
                            role_id: roles[0]?.id,

                        })


                    }
                })
                .catch(error => {

                    responseHandler(error, "error", false);
                    if (error.response?.status === 404) navigate("/notfound", { replace: true })
                })


            await axios
                .get("/role/index?relations", {

                    headers: {

                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(res => {

                    if (res.data.status) {

                        setIsLoad(false);
                        setOptionsRoles(res.data.data)
                    }
                    else console.log(res);
                })
                .catch(error => {

                    responseHandler(error, "error");
                })

        }

        runGet();


    }, [updateInfo])


    //for inputs handler
    const regex = /^(\+98|0098|98|0)?9\d{9}$/;
    const inputHandler = (e) => {

        let first;
        if (e.target.name === "mobile" || e.target.name === "password") first = changeFaToEn(e.target.value);
        setEditeUserInfo({ ...editeUserInfo, [e.target.name]: e.target.name === "mobile" || e.target.name === "password" ? first : e.target.value })


    }

    //for checks handler
    const checksHandler = (e, type) => {

        let totalCheck;

        if (type === "checkbox") e.target.checked ? totalCheck = 1 : totalCheck = 0;
        else e.target.value === "male" ? totalCheck = "male" : totalCheck = "female";

        setEditeUserInfo({ ...editeUserInfo, [e.target.name]: totalCheck })


    }


    //for select of role handler
    const handleChange = (e) => {

        setEditeUserInfo({...editeUserInfo , role_id : e})

    }

    

    //for click on edite btn
    const editeUserHandler = async () => {

        setCheckBtnEdite(true);

        let bodySend = {};

        for (let item in copyOfData) {

            if (copyOfData[item] !== editeUserInfo[item]) bodySend[item] = editeUserInfo[item];
        }

        const keysBodySend = Object.keys(bodySend);
        if (keysBodySend.length === 0) {

            notify("هیچ موردی را برای ویرایش تغییر نداید", "error", 2000);
            setCheckBtnEdite(false);
            return;

        }



        let checkMobile;
        if (bodySend.mobile) checkMobile = regex.test(bodySend.mobile);

        if (checkMobile === false) {

            notify("شماره موبایل صحیح وارد نشده", "error");
            setCheckBtnEdite(false);
            return;
        }



        if (bodySend.first_name === "" || bodySend.last_name === "" || bodySend.mobile === "") {

            notify("برخی از موارد خالی هستند", "error");
            setCheckBtnEdite(false);
            return;

        }


        await axios
            .put(`/user/update/${params.id}`, bodySend, {

                headers: {

                    'Authorization': `Bearer ${token}`,
                }
            })
            .then(res => {


                responseHandler(res, "succ");
                if (res.data.status) {


                    axios.get(`/user/show/${params.id}`, { headers: { 'Authorization': `Bearer ${token}` } })
                        .then(res => {

                            if (res.data.status) {

                                setUpdateLocalStorage(!UpdateLocalStorage);
                                navigate("/dashboard/users");

                            }
                        })
                        .catch(error => {

                            responseHandler(error, "error");
                            setCheckBtnEdite(false);
                        })



                    setIsLoad(true);
                    setUpdateInfo(!updateInfo);


                }

                setCheckBtnEdite(false);
            })
            .catch(error => {

                responseHandler(error, "error");
                setCheckBtnEdite(false);
            })



    }


    //for style role label
    const roleStyle = {

        display: "flex",
        justifyContent: "flex-start",
        margin: "8px 0",
        backgroundColor: "#e3242b",
        color : "#fff" ,
        display: "inline-block",
        padding: "0.1rem 0.6rem",
        borderRadius: "4px",
    }




    return (


        !isLoad && isLogin ?

            <>

                <div className={styles.EditeUserDiv}>

                    <div className={styles.title}>

                        <span>{`ویرایش ${copyOfData.first_name} ${copyOfData.last_name}`}</span>
                        <i className="bi bi-person-fill-gear"></i>

                    </div>



                    {params.id == userData.user.id ? <div>شما مجاز به ویرایش خود از این طریق نیستید</div> :


                        <>


                            <div className={styles.InputsDiv}>

                                <div className={copyStyleNewUser.Inputs}>
                                    <label htmlFor='nameEdite'>نام:</label>
                                    <input type='text' id="nameEdite" name="first_name" value={editeUserInfo.first_name} onChange={inputHandler} />
                                </div>


                                <div className={copyStyleNewUser.Inputs}>
                                    <label htmlFor='lastNameEdite'>نام خانوادگی:</label>
                                    <input type='text' id="lastNameEdite" name="last_name" value={editeUserInfo.last_name} onChange={inputHandler} />
                                </div>



                                <div className={copyStyleNewUser.genderDiv}>


                                    <span>جنسیت: </span>

                                    <label className={copyStyleNewUser.conRadio}><i className="bi bi-gender-male ms-1 me-3"></i>آقا
                                        <input type="radio" name="gender" checked={editeUserInfo.gender === "male" && "checked"} value="male" onChange={(e) => checksHandler(e, "radio")} />
                                        <span className={copyStyleNewUser.checkmark2}></span>
                                    </label>


                                    <label className={copyStyleNewUser.conRadio}><i className="bi bi-gender-female ms-1"></i>خانم
                                        <input type="radio" name="gender" checked={editeUserInfo.gender === "female" && "checked"} value="female" onChange={(e) => checksHandler(e, "radio")} />
                                        <span className={copyStyleNewUser.checkmark2}></span>
                                    </label>

                                </div>


                                <div className={copyStyleNewUser.Inputs}>
                                    <label htmlFor='mobileEdite'>شماره موبایل:</label>
                                    <input type='text' id="mobileEdite" name="mobile" value={editeUserInfo.mobile} onChange={inputHandler} />
                                </div>




                                <div className={copyStyleNewUser.Inputs}>
                                    <label htmlFor='passwordEdite'>رمز عبور جدید:</label>
                                    <input type='password' id="passwordEdite" name="password" value={editeUserInfo.password} onChange={inputHandler} />
                                </div>



                                <div style={roleStyle}>
                                    <span>نقش:</span>
                                </div>
                                <Select

                                    // mode="multiple"
                                    
                                    style={{ width: '100%', marginBottom: "1.2rem" }}
                                    placeholder={`بدون نفش`}
                                    defaultValue={editeUserInfo.role_id}
                                    onSelect={handleChange}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                // dropdownStyle={{ zIndex: 1060 }}



                                >

                                    {optionsRoles.map((item, index) => <Option key={item.id} value={item.id}>{item.name}</Option>)}


                                </Select>








                                <div className={copyStyleNewUser.Divchecks}>

                                    <div className={copyStyleNewUser.checks}>

                                        <label className={copyStyleNewUser.conCehcks}>مدیر
                                            <input type="checkbox" name="is_admin" checked={editeUserInfo.is_admin} onChange={(e) => checksHandler(e, "checkbox")} />
                                            <span className={copyStyleNewUser.checkmark}></span>
                                        </label>


                                        <label className={copyStyleNewUser.conCehcks}>فعال
                                            <input type="checkbox" name="is_active" checked={editeUserInfo.is_active} onChange={(e) => checksHandler(e, "checkbox")} />
                                            <span className={copyStyleNewUser.checkmark}></span>
                                        </label>


                                        {/* <label className={copyStyleNewUser.conCehcks}>استف
                                            <input type="checkbox" name="is_staff" checked={editeUserInfo.is_staff} onChange={(e) => checksHandler(e, "checkbox")} />
                                            <span className={copyStyleNewUser.checkmark}></span>
                                        </label> */}


                                    </div>



                                </div>

                            </div>

                            <div className={`${styles.btns} d-grid gap-2 col-6 mx-auto mt-5`}>

                                <button className='btn btn-primary' disabled={checkBtnEdite} onClick={editeUserHandler}>
                                    ویرایش
                                    {checkBtnEdite && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
                                </button>
                                <button className='btn btn-secondary' onClick={() => navigate(-1)}>بازگشت</button>
                            </div>

                        </>
                    }




                </div >


            </>


            :

            <Loading color="#7545fb" />
    );
};

export default EditeUser;