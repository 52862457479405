import React, { useEffect, useState, useContext } from 'react';

//styles
import styles from "./RolesPage.module.scss";
import copyStyleOfNewuserModal from "../../shared/NewUserModal.module.scss";

//contexts
import { myUserDataContext } from '../../App';

//components
import Loading from '../../shared/Loading';
import ShowNothing from '../../shared/ShowNothing';

//dependencyes
import axios from 'axios';
import { Select } from 'antd';
import { notify } from '../../functions/toasts';
import { Link } from 'react-router-dom';

//functions
import { responseHandler } from '../../functions/responseHandler';
import { changeFaToEn } from '../../functions/changeFaToEn';

//shared
import ModalYesorNo from '../../shared/ModalYesorNo';



const RolesPage = () => {

    const data = useContext(myUserDataContext);
    const { userData, isLogin } = data;
    const { token } = userData;

    const [listRoles, setListRoles] = useState([]);
    const [isLoad, setIsLoad] = useState(true);


    const [UpdateComponent, setUpdateComponent] = useState(false);


    const [checkBtnNewRole, setCheckBtnNewRole] = useState(false);
    const [dataNewRole, setDataNewRole] = useState({});
    const [optionsPermissions, setOptionsPermissions] = useState([]);
    const { Option } = Select;




    //for get list of roles
    useEffect(() => {

        setIsLoad(true);
        const fetchData = async () => {
            try {
                const response1 = await axios.get("/role/index?relations[]=permissions", {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                responseHandler(response1, "succ", false);
                if (response1.data.status) {
                    setListRoles(response1.data.data);

                }

                const response2 = await axios.get("/permission/index", {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                responseHandler(response2, "succ", false);
                if (response2.data.status) {
                    setOptionsPermissions(response2.data.data)
                    setIsLoad(false);
                }
            } catch (error) {

                responseHandler(error, "error");
            }
        };

        fetchData();


    }, [UpdateComponent])


    //for show permissions div
    const showPerHandler = (e, type) => {

        if (type === "show") e.target.nextElementSibling.style.display = "flex";
        else e.target.nextElementSibling.style.display = "none";

    }



    //for input of create role
    const inputHandler = (e) => {

        const first = changeFaToEn(e.target.value);
        setDataNewRole({ ...dataNewRole, [e.target.name]: first })

    }

    //for selectHandler
    const handleChange = (e) => {

        setDataNewRole({ ...dataNewRole, permission_ids: e })

    }



    //for new role create
    const newRoleHandler = () => {

        setCheckBtnNewRole(true);

        if (dataNewRole.name === "" || dataNewRole.name === undefined) {

            notify("نام نقش الزامی است", "error");
            setCheckBtnNewRole(false);
            return;
        }

        axios
            .post("/role/create", dataNewRole, {

                headers: {

                    'Authorization': `Bearer ${token}`,
                }
            })
            .then(res => {

                if (res.data.status) {

                    responseHandler(res, "succ");
                    setDataNewRole({})
                    setUpdateComponent(!UpdateComponent);
                    setCheckBtnNewRole(false);

                }
            })
            .catch(error => {

                responseHandler(error, "error");
                setCheckBtnNewRole(false);

            })
    }



    //for delete roles
    const deleteRoleHandler = (id) => {


        axios
            .delete(`/role/delete/${id}`, {

                headers: {

                    'Authorization': `Bearer ${token}`
                }
            })
            .then(res => {

                if (res.data.status) {

                    responseHandler(res, "succ");
                    setUpdateComponent(!UpdateComponent);
                }
            })
            .catch(error => {

                responseHandler(error, "error");
            })

    }


    return (

        !isLoad && isLogin ?

            listRoles.length ?


                <div className={styles.rolePageDiv}>
                    
                    <div className={styles.createRole}>

                        <p>
                            <a className="btn btn-primary" data-bs-toggle="collapse" href="#collNewRole" role="button" aria-expanded="false" aria-controls="collapseExample">
                                ایجاد نقش جدید  <i className="bi bi-plus-lg"></i>
                            </a>
                        </p>

                        <div className="collapse mb-3" id="collNewRole">
                            <div className="card card-body">

                                <div className={copyStyleOfNewuserModal.Inputs}>
                                    <label htmlFor='roleName'>نام نقش:</label>
                                    <input type='text' id="roleName" name="name" value={dataNewRole.name} onChange={inputHandler} />
                                </div>


                                <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: "8px" }}>
                                    <span>دسترسی ها:</span>
                                </div>
                                <Select

                                    mode="multiple"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder={`بدون دسترسی`}
                                    defaultValue={[]}
                                    onChange={handleChange}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                // dropdownStyle={{ zIndex: 1060 }}
                                >

                                    {optionsPermissions.map((item, index) => <Option key={item.id} value={item.id}>{item.fa_name}</Option>)}
                                </Select>



                                <div className="mt-4">

                                    <button className='btn btn-primary' disabled={checkBtnNewRole} onClick={newRoleHandler}>
                                        ایجاد
                                        {checkBtnNewRole && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
                                    </button>

                                </div>

                            </div>
                        </div>

                    </div>



                    <div className={styles.cards}>


                        {listRoles.map((item, index) => {

                            return <div key={index} className={styles.card}>

                                <div className={styles.actionBtns}>

                                    <Link to={`/dashboard/roles/edite/${item.id}`} className={styles.editeIcon} ><i className={`bi bi-pencil-square`}></i></Link>
                                    <i className={`bi bi-trash3-fill me-2 ${styles.deleteIcon}`} data-bs-toggle="modal" data-bs-target={`#iam${index}`} ></i>

                                    <ModalYesorNo idModal={`iam${index}`} doBtn="حذف" text={`با اطمینان نقش ${item.name} را حذف می‌کنید؟`} Click={() => deleteRoleHandler(item.id)} />

                                </div>

                                <img className={styles.cardImage} src='https://cdn.hamkadeh.com/up-files/1688047921.png' alt="picCard" />
                                <span className={styles.cardName}>{item.name}</span>

                                <div>


                                </div>
                                <div className={styles.divCon}>

                                    <span className={styles.dastresi} onMouseEnter={(e) => showPerHandler(e, "show")} onMouseLeave={(e) => showPerHandler(e, "hide")}>دسترسی ها !</span>
                                    <div className={styles.permissionsAll}>

                                        {

                                            item.permissions.length === 0 ?

                                                <span style={{ color: "#fff", fontSize: "0.7rem" }}>بدون دسترسی</span>


                                                :

                                                item.permissions.map((perItem, perIndex) => {

                                                    return <div className={styles.permission} key={perIndex}>

                                                        <span>{perItem.fa_name}</span>

                                                    </div>
                                                })


                                        }


                                    </div>
                                </div>




                            </div>
                        })}



                    </div>




                </div>

                :

                <ShowNothing text="نقشی برای نمایش وجود ندارد" />
            :
            <Loading color="#7545fb" />


    );
};

export default RolesPage;