import React, { useEffect, useContext, useState } from 'react';

//styles
import styles from "./Profile.module.scss";
import copyStyleOfNewuserModal from "../../shared/NewUserModal.module.scss";

//functions
import { responseHandler } from '../../functions/responseHandler';
import { changeFaToEn } from '../../functions/changeFaToEn';

//dependency
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { notify } from '../../functions/toasts';


//contexs
import { myUserDataContext } from '../../App';

//components
import Loading from '../../shared/Loading';




const Profile = () => {


    const navigate = useNavigate();

    const data = useContext(myUserDataContext);
    const { userData, isLogin, UpdateLocalStorage, setUpdateLocalStorage } = data;
    const { token } = userData;

    const [checkBtnSabt, setCheckBtnSabt] = useState(false);

    const [copyOfData, setCopyOfData] = useState({});

    const [currentUserData, setCurrentUserData] = useState({});
    const [isLoad, setIsLoad] = useState(true);


    const [updateInfo, setUpdateInfo] = useState(false);


    //for change password
    const [dataOfPass, setDataOfPass] = useState({

        old_password : "",
        new_password : "",
        new_password_confirmation : ""

    });
    const [checkBtnChangePass , setCheckBtnChangePass] = useState(false);

    useEffect(() => {

        axios.get('/profile/current-user', {

            headers: {

                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => {
                
                responseHandler(res, "succ", false)
                if (res.data.status) {

                    setCurrentUserData(res.data.data);
                    setCopyOfData(res.data.data)
                    setIsLoad(false);

                }



            })
            .catch(error => {

                responseHandler(error, "error");
            })

    }, [updateInfo])


    //for inputs handler
    const inputHandler = (e) => {

        let first = e.target.value;
        if (e.target.name === "password") first = changeFaToEn(e.target.value);
        setCurrentUserData({ ...currentUserData, [e.target.name]: first })

    }

    //for checks handler
    const checksHandler = (e, type) => {

        let totalCheck;
        e.target.value === "male" ? totalCheck = "male" : totalCheck = "female";
        setCurrentUserData({ ...currentUserData, [e.target.name]: totalCheck })

    }


    const changePassInputsHandlers = (e) => {

        let first;
        first = changeFaToEn(e.target.value);
        setDataOfPass({ ...dataOfPass, [e.target.name]: first })
    }



    //for click ob sabt btn
    const SabtHandler = async () => {

        setCheckBtnSabt(true);

        let bodySend = {};

        for (let item in copyOfData) {

            if (copyOfData[item] !== currentUserData[item]) bodySend[item] = currentUserData[item];
        }

        const keysBodySend = Object.keys(bodySend);
        if (keysBodySend.length === 0) {

            notify("هیچ موردی را برای ویرایش تغییر نداید", "error", 2000);
            setCheckBtnSabt(false);
            return;

        }

        if (bodySend.first_name === "" || bodySend.last_name === "") {

            notify("برخی از موارد خالی هستند", "error");
            setCheckBtnSabt(false);
            return;

        }

        await axios
            .post(`/profile/change-info`, bodySend, {

                headers: {

                    'Authorization': `Bearer ${token}`,
                }
            })
            .then(res => {


                responseHandler(res, "succ");
                if (res.data.status) {


                    axios.get(`/user/show/${userData.user.id}`, { headers: { 'Authorization': `Bearer ${token}` } })
                        .then(res => {

                            if (res.data.status) {

                                setUpdateLocalStorage(!UpdateLocalStorage)

                            }
                        })
                        .catch(error => {

                            responseHandler(error, "error");
                            setCheckBtnSabt(false);
                        })



                    setIsLoad(true);
                    setUpdateInfo(!updateInfo);


                }

                setCheckBtnSabt(false);
            })
            .catch(error => {

                responseHandler(error, "error");
                setCheckBtnSabt(false);
            })


    }



    //for click on changePass btn
    const changePassHandler = () =>{

        setCheckBtnChangePass(true);

        if(dataOfPass.old_password === ""){

            notify("رمز عبور فعلی وارد نشده است" , "error");
            setCheckBtnChangePass(false);
            return;
        }
        if(dataOfPass.new_password === ""){

            notify("رمز عبور جدید وارد نشده است" , "error");
            setCheckBtnChangePass(false);
            return;
        }
        if(dataOfPass.new_password_confirmation === ""){

            notify("تکرار رمز عبور جدید وارد نشده است" , "error");
            setCheckBtnChangePass(false);
            return;
        }

        if(dataOfPass.new_password !== dataOfPass.new_password_confirmation){

            notify("رمز عبور جدید با تکرار آن مطابقت ندارد" , "error");
            setCheckBtnChangePass(false);
            return;
        }

        axios
            .post("/profile/change-password" , dataOfPass , {

                headers : {

                    'Authorization' : `Bearer ${token}`,
                }
            })
            .then(res =>{

                responseHandler(res , "succ" , 2500);
                if(res.data.status){

                    setDataOfPass({

                        old_password : "",
                        new_password : "",
                        new_password_confirmation : ""
                
                    })

                }
                setCheckBtnChangePass(false);
            })
            .catch(error =>{

                responseHandler(error , "error");
                setCheckBtnChangePass(false);
            })
    }












    return (

        !isLoad && isLogin ?

            <>

                <div className={styles.ProfileDiv}>

                    <div className={styles.PicProfile} >

                        <img src={copyOfData.gender === "male" ? 'https://cdn.hamkadeh.com/up-files/1685744538.png' : 'https://cdn.hamkadeh.com/up-files/1686400650.png'} alt='PicProfile' />

                    </div>

                    <div className={styles.info}>

                        <div className={copyStyleOfNewuserModal.Inputs}>
                            <label htmlFor='nameProfile'>نام:</label>
                            <input type='text' id="nameProfile" name="first_name" value={currentUserData.first_name} onChange={inputHandler} />
                        </div>


                        <div className={copyStyleOfNewuserModal.Inputs}>
                            <label htmlFor='lastNameProfile'>نام خانوادگی:</label>
                            <input type='text' id="lastNameProfile" name="last_name" value={currentUserData.last_name} onChange={inputHandler} />
                        </div>

                        <div className={copyStyleOfNewuserModal.genderDiv}>


                            <span>جنسیت: </span>

                            <label className={copyStyleOfNewuserModal.conRadio}><i className="bi bi-gender-male ms-1 me-3"></i>آقا
                                <input type="radio" name="gender" checked={currentUserData.gender === "male" && "checked"} value="male" onChange={(e) => checksHandler(e, "radio")} />
                                <span className={copyStyleOfNewuserModal.checkmark2}></span>
                            </label>


                            <label className={copyStyleOfNewuserModal.conRadio}><i className="bi bi-gender-female ms-1"></i>خانم
                                <input type="radio" name="gender" checked={currentUserData.gender === "female" && "checked"} value="female" onChange={(e) => checksHandler(e, "radio")} />
                                <span className={copyStyleOfNewuserModal.checkmark2}></span>
                            </label>

                        </div>


                    </div>


                    <div className={styles.btns}>

                        <button className='btn btn-primary ms-2' disabled={checkBtnSabt} onClick={SabtHandler}>
                            ثبت
                            {checkBtnSabt && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
                        </button>
                        <button className='btn btn-dark' onClick={() => navigate('/')}>بازگشت به داشبورد</button>

                    </div>



                    <div className={styles.changePass}>

                        <p>
                            <a className="btn btn-warning" data-bs-toggle="collapse" href="#collChangePass" role="button" aria-expanded="false" aria-controls="collapseExample">
                                تغییر رمز عبور
                            </a>
                        </p>

                        <div className="collapse" id="collChangePass">
                            <div className="card card-body">

                                <div className={copyStyleOfNewuserModal.Inputs}>
                                    <label htmlFor='oldPass'>رمز عبور فعلی:</label>
                                    <input type='password' id="oldPass" name="old_password" value={dataOfPass.old_password} onChange={changePassInputsHandlers} />
                                </div>


                                <div className={copyStyleOfNewuserModal.Inputs}>
                                    <label htmlFor='newPass'>رمز عبور جدید:</label>
                                    <input type='password' id="newPass" name="new_password" value={dataOfPass.new_password} onChange={changePassInputsHandlers} />
                                </div>

                                <div className={copyStyleOfNewuserModal.Inputs}>
                                    <label htmlFor='newPassAgain'>تکرار رمز عبور جدید:</label>
                                    <input type='password' id="newPassAgain" name="new_password_confirmation" value={dataOfPass.new_password_confirmation} onChange={changePassInputsHandlers} />
                                </div>

                                <div className={styles.btnChangePass}>

                                    <button className='btn btn-warning' disabled={checkBtnChangePass} onClick={changePassHandler}>
                                        تغییر رمز
                                        {checkBtnChangePass && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
                                    </button>

                                </div>

                            </div>
                        </div>

                    </div>


                </div>




            </>

            :

            <Loading color="#7545fb" />
    );
};

export default Profile;