import React from 'react';
import ReactDOM from 'react-dom/client';
import "./custom.scss";
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';

import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap-icons/font/bootstrap-icons.css";


const baseUrl = document.getElementById("baseUrl").value;

// base url APIS
axios.defaults.baseURL = `${baseUrl}api`;
// axios.defaults.baseURL = "https://landing.getz.ir/api";
// axios.defaults.baseURL = "https://eu.getz.ir/api";
// axios.defaults.baseURL = "http://127.0.0.1:8000/api";
// axios.defaults.baseURL = "http://192.168.23.34:8000/api";


document.addEventListener('dragstart', function(event) {
  event.preventDefault();
});

document.addEventListener('drop', function(event) {
  event.preventDefault();
});



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <BrowserRouter>

    <App />
    
  </BrowserRouter>

);
