import React, { useEffect, useState } from 'react';
import axios from 'axios';


//styles
import styles from "./NewUserModal.module.scss";

//functions
import { changeFaToEn } from '../functions/changeFaToEn';
import { notify } from "../functions/toasts";
import { responseHandler } from '../functions/responseHandler';



const NewUserModal = ({ token, setAllUsers, setUpdateListUsers, UpdateListUsers }) => {


    const [checkBtnCreate, setCheckBtnCreate] = useState(false);
    const [newUserInfo, setNewUserInfo] = useState({

        name: "",
        lastName: "",
        mobile: "",
        password: "",
        isAdmin: 0,
        // isStaff: 0,
        gender: "male",
        isActive: 1,

    })


    

    //for inputs handler
    const regex = /^(\+98|0098|98|0)?9\d{9}$/;
    const inputHandler = (e) => {

        let first;
        if (e.target.name === "mobile" || e.target.name === "password") first = changeFaToEn(e.target.value);
        setNewUserInfo({ ...newUserInfo, [e.target.name]: e.target.name === "mobile" || e.target.name === "password" ? first : e.target.value })

    }

    //for checks handler
    const checksHandler = (e , type) => {

        let totalCheck;
       
        if(type === "checkbox")  e.target.checked ? totalCheck = 1 : totalCheck = 0;
        else e.target.value === "male" ? totalCheck = "male" : totalCheck = "female";

        setNewUserInfo({ ...newUserInfo, [e.target.name]: totalCheck })

    }


    //for click on create newUser
    const createUserHandler = () => {

        setCheckBtnCreate(true);
        const { name, lastName, mobile, password, isAdmin, isStaff, isActive, gender } = newUserInfo;

        if (name === "" || lastName === "" || mobile === "" || password === "") {

            notify("برخی از موارد خواسته شده وارد نشده اند", "error");
            setCheckBtnCreate(false)
            return;
        }

        const checkMobile = regex.test(mobile);

        if (!checkMobile) {

            notify("شماره موبایل صحیح وارد نشده", "error");
            setCheckBtnCreate(false);
            return;
        }

        const bodyNewUser = {

            first_name: name,
            last_name: lastName,
            mobile: mobile,
            password: password,
            is_admin: isAdmin,
            // is_staff: isStaff,
            gender: gender,
            is_active: isActive,

        }

        axios
            .post("/user/create", bodyNewUser, {

                headers: {

                    'Authorization': `Bearer ${token}`
                }
            })
            .then(res => {

                responseHandler(res, "succ");

                setAllUsers([]);
                setUpdateListUsers(!UpdateListUsers);

                setCheckBtnCreate(false);
                document.getElementById('closeNewUserModal').click();
            })
            .catch(error => {

                responseHandler(error, "error");
                setCheckBtnCreate(false);
            })

    }



    //for click on closeBtnModal and reset the inputs
    const closeBtnHandler = () => {

        setNewUserInfo({

            name: "",
            lastName: "",
            mobile: "",
            password: "",
            isAdmin: 0,
            // isStaff: 0,
            gender: "male",
            isActive: 1,

        })

    }

    return (

        <>

            <div className="modal fade" id="newUserModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h1 className="modal-title fs-5" id="exampleModalLabel" >ایجاد کاربر جدید</h1>
                        </div>
                        <div className="modal-body">


                            <div className={styles.divNewUser}>

                                <div className={styles.Inputs}>
                                    <label htmlFor='name'>نام:</label>
                                    <input type='text' id="name" name="name" value={newUserInfo.name} onChange={inputHandler} />
                                </div>


                                <div className={styles.Inputs}>
                                    <label htmlFor='lastName'>نام خانوادگی:</label>
                                    <input type='text' id="lastName" name="lastName" value={newUserInfo.lastName} onChange={inputHandler} />
                                </div>


                                <div className={styles.genderDiv}>
                                    

                                    <span>جنسیت: </span>

                                    <label className={styles.conRadio}><i className="bi bi-gender-male ms-1 me-3"></i>آقا 
                                        <input type="radio"  name="gender" checked={newUserInfo.gender === "male" && "checked"} value="male" onChange={(e) => checksHandler(e , "radio")} />
                                            <span className={styles.checkmark2}></span>
                                    </label>


                                    <label className={styles.conRadio}><i className="bi bi-gender-female ms-1"></i>خانم
                                        <input type="radio" name="gender" checked={newUserInfo.gender === "female" && "checked"} value="female" onChange={(e) => checksHandler(e , "radio")} />
                                            <span className={styles.checkmark2}></span>
                                    </label>

                                </div>


                                <div className={styles.Inputs}>
                                    <label htmlFor='mobile'>شماره موبایل:</label>
                                    <input type='text' id="mobile" name="mobile" value={newUserInfo.mobile} onChange={inputHandler} />
                                </div>

                                <form className={styles.Inputs}>
                                    <label htmlFor='password'>رمز عبور:</label>
                                    <input type='password' id="password" name="password" value={newUserInfo.password} onChange={inputHandler} />
                                </form>

                                <div className={styles.Divchecks}>

                                    <div className={styles.checks}>

                                        <label className={styles.conCehcks}>مدیر
                                            <input type="checkbox" name="isAdmin" checked={newUserInfo.isAdmin} onChange={(e) => checksHandler(e , "checkbox")} />
                                            <span className={styles.checkmark}></span>
                                        </label>


                                        <label className={styles.conCehcks}>فعال
                                            <input type="checkbox" name="isActive" checked={newUserInfo.isActive} onChange={(e) => checksHandler(e , "checkbox")} />
                                            <span className={styles.checkmark}></span>
                                        </label>


                                        {/* <label className={styles.conCehcks}>استف
                                            <input type="checkbox" name="isStaff" checked={newUserInfo.isStaff} onChange={(e) => checksHandler(e , "checkbox")} />
                                            <span className={styles.checkmark}></span>
                                        </label> */}


                                    </div>



                                </div>

                            </div>



                        </div>
                        <div className="modal-footer" style={{ direction: "ltr" }}>
                            <button type="button" className="btn btn-secondary" id="closeNewUserModal" data-bs-dismiss="modal" onClick={closeBtnHandler}>بستن</button>
                            <button type="button" className="btn btn-primary" onClick={createUserHandler} disabled={checkBtnCreate}>
                                ایجاد کاربر
                                {checkBtnCreate && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}

                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default NewUserModal;