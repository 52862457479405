import React, { useContext, useState, useEffect } from 'react';

//styles
import styles from "./TrashList.module.scss";

//contexs
import { myUserDataContext } from '../../App';

//dependencyes
import axios from 'axios';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import queryString from 'query-string';

//functions
import { responseHandler } from '../../functions/responseHandler';

//components
import Loading from '../../shared/Loading';
import ShowNothing from '../../shared/ShowNothing';
import ModalYesorNo from '../../shared/ModalYesorNo';
import MyPagination from "../../shared/MyPagination";

const TrashList = ({ show }) => {

    const navigate = useNavigate();
    const locations = useLocation().search;
    const myQueryString = queryString.parse(locations);


    const data = useContext(myUserDataContext);
    const { userData, isLogin } = data;
    const { token } = userData;



    const [trashListData, setTarshListData] = useState([]);
    const [isLoad, setIsLoad] = useState(true);
    const [metaPages, setMetaPage] = useState({});
    const [UpdateTrashList, setUpdateTrashList] = useState(false);


        //for make queries
        let joinqueries = [];
        for(let item in myQueryString){
    
            if(item === "page") continue ;
            const temp = `${item}=${myQueryString[item]}`;
            joinqueries.push(temp);
            
        }
        joinqueries = joinqueries.join("&");



    //get all Tarshes
    useEffect(() => {

        let UrlPost = ``;

        if (show === "user") UrlPost = `/user/trash/list?page=${myQueryString.page}${joinqueries !== "" ? `&${joinqueries}` : ""}`;
        else if (show === "landing") UrlPost = `/landing/trash/list?relations[]=user&relations[]=template&page=${myQueryString.page}${joinqueries !== "" ? `&${joinqueries}` : ""}`;
        else if (show === "template") UrlPost = `/template/trash/list?page=${myQueryString.page}${joinqueries !== "" ? `&${joinqueries}` : ""}`;


        setIsLoad(true);
        axios.get(UrlPost, {

            headers: {

                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => {

                if (res.data.status) {

                    setTarshListData(res.data.data);
                    setIsLoad(false);
                    setMetaPage(res.data.meta)
                }
            })
            .catch(error => {

                responseHandler(error, "error");
            })

    }, [UpdateTrashList])



    //for when click on restore btn
    const restoreHandler = (id) => {

        axios
            .post(`/${show}/trash/restore/${id}`, {}, {

                headers: {

                    'Authorization': `Bearer ${token}`,
                }
            })
            .then(res => {

                responseHandler(res, "succ");
                if (res.data.status) {

                    setUpdateTrashList(!UpdateTrashList);

                }
            })
            .catch(error => {

                responseHandler(error, "error");
            })

    }



    //for click on any of paginations
    const paginationHandler = (url) => {

        let UrlPost = ``;

        if (show === "user") UrlPost = `/user/trash/list?page=${url}${joinqueries !== "" ? `&${joinqueries}` : ""}`;
        else if (show === "landing") UrlPost = `/landing/trash/list?relations[]=user&relations[]=template&page=${url}${joinqueries !== "" ? `&${joinqueries}` : ""}`;
        else if (show === "template") UrlPost = `/template/trash/list?page=${url}${joinqueries !== "" ? `&${joinqueries}` : ""}`;


        setIsLoad(true);
        axios.get(UrlPost, {

            headers: {

                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => {

                if (res.data.status) {


                    setTarshListData(res.data.data);
                    setIsLoad(false);
                    setMetaPage(res.data.meta)
                }
            })
            .catch(error => {

                responseHandler(error, "error");
            })

    }


    //for paginations
    if (trashListData.length) {

        var length = metaPages.links.length;
    }

    let myPath = "";
    if (show === "user") myPath = "/dashboard/users/trash-list";
    else if (show === "template") myPath = "/dashboard/showTemplates/trash-list";
    else if (show === "landing") myPath = "/dashboard/showLandings/trash-list";






    return (
        !isLoad && isLogin ?

            trashListData.length ?

                <div className={styles.trashDiv}>

                    <div className={styles.topOfTrash}>

                        <i className="bi bi-trash3-fill"></i>

                    </div>

                    {show === "user" && <div className="table-responsive mx-2"  >
                        <table className="table table-bordered table-striped table-danger" >

                            <thead>

                                <tr>

                                    <th>ردیف</th>
                                    <th>نام کاربر</th>
                                    <th>نقش</th>
                                    <th>موبایل</th>
                                    <th>عملیات</th>

                                </tr>

                            </thead>


                            <tbody>

                                {trashListData.map((item, index) => {



                                    return <tr key={index}>

                                        <td>{metaPages.from + index}</td>
                                        <td>{`${item.first_name} ${item.last_name}`}</td>
                                        <td>{+item.is_admin ? "ادمین" : "کاربر"}</td>
                                        <td>{item.mobile}</td>
                                        <td>

                                            <button className='btn btn-danger btn-sm mx-1' disabled>حذف دائمی</button>
                                            <button className='btn btn-primary btn-sm mx-1' data-bs-toggle="modal" data-bs-target={`#iam${index}`}>بازگردانی</button>

                                            <ModalYesorNo idModal={`iam${index}`} doBtn="بازگردانی" color="primary" text={`${item.first_name} ${item.last_name}  بازگردانی شود؟`} Click={() => restoreHandler(item.id)} />
                                        </td>

                                    </tr>
                                })}


                            </tbody>


                        </table>
                    </div>}

                    {show === "landing" && <div className="table-responsive mx-2"  >
                        <table className="table table-bordered table-striped table-danger" >

                            <thead>

                                <tr>

                                    <th>ردیف</th>
                                    <th>نام لندینگ</th>
                                    <th>نام قالب</th>
                                    <th>آدرس</th>
                                    <th>سازنده لندینگ</th>
                                    <th>عملیات</th>

                                </tr>

                            </thead>


                            <tbody>


                                {trashListData.map((item, index) => {


                                    return <tr key={index}>

                                        <td>{metaPages.from + index}</td>
                                        <td>{item.title}</td>
                                        <td>{item.template?.title ?? "حذف شده"}</td>
                                        <td>{item.slug}</td>
                                        <td>{item.user === null ? "حذف شده" : `${item.user.first_name} ${item.user.last_name}`}</td>


                                        <td>

                                            <button className='btn btn-danger btn-sm mx-1' disabled>حذف دائمی</button>
                                            <button className='btn btn-primary btn-sm mx-1' data-bs-toggle="modal" data-bs-target={`#iam${index}`}>بازگردانی</button>

                                            <ModalYesorNo idModal={`iam${index}`} doBtn="بازگردانی" color="primary" text={`${item.title} بازگردانی شود؟`} Click={() => restoreHandler(item.id)} />

                                        </td>

                                    </tr>
                                })}



                            </tbody>


                        </table>
                    </div>}

                    {show === "template" && <div className="table-responsive mx-2"  >
                        <table className="table table-bordered table-striped table-danger" >

                            <thead>

                                <tr>

                                    <th>ردیف</th>
                                    <th>نام قالب</th>
                                    <th>آدرس</th>
                                    <th>تعداد لندینگ</th>
                                    <th style={{ textAlign: "center" }}>پیش نمایش</th>
                                    <th>عملیات</th>

                                </tr>

                            </thead>


                            <tbody>


                                {trashListData.map((item, index) => {

                                    return <tr key={index}>

                                        <td>{metaPages.from + index}</td>
                                        <td>{item.title}</td>
                                        <td>{item.slug}</td>

                                        <td className='user-select-none'>{`${item.landings_count} لندینگ`}</td>
                                        <td style={{ textAlign: "center" }}>{item.screenshot_url !== null && <img src={item.screenshot_url} alt={`picTemplate${item.id}`} className={styles.screenimage} />}</td>

                                        <td>

                                            <button className='btn btn-danger btn-sm mx-1' disabled>حذف دائمی</button>
                                            <button className='btn btn-primary btn-sm mx-1' data-bs-toggle="modal" data-bs-target={`#iam${index}`}>بازگردانی</button>

                                            <ModalYesorNo idModal={`iam${index}`} doBtn="بازگردانی" color="primary" text={`${item.title} بازگردانی شود؟`} Click={() => restoreHandler(item.id)} />


                                        </td>

                                    </tr>
                                })}



                            </tbody>


                        </table>



                    </div>}


                
                    {length !== 3 && <MyPagination meta={metaPages} myPath={myPath} paginationHandler={paginationHandler} joinqueries={joinqueries}/>}



                    <div className={styles.btns}>

                        <Link to={`/dashboard/${show === "user" ? 'users' : show === "landing" ? 'showLandings' : 'showTemplates'}`}><button className='btn btn-primary'>{`بازگشت به ${show === "user" ? 'کاربران' : show === "landing" ? 'لندینگ ها' : 'قالب ها'}`}</button></Link>

                    </div>




                </div>

                :

                <ShowNothing text="این سطل زباله خالی است" />

            :
            <Loading color="#7545fb" />
    );
};

export default TrashList;