import React, { useContext, useState, useEffect } from 'react';


//contexts
import { myUserDataContext } from '../../App';

//functions
import { responseHandler } from '../../functions/responseHandler';

//dependencys
import { useLocation, Link, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';

//components
import Loading from '../../shared/Loading';
import ModalYesorNo from '../../shared/ModalYesorNo';
import ShowNothing from '../../shared/ShowNothing';

//shared
import MyPagination from "../../shared/MyPagination";

//styles
import styles from "./ShowLandings.module.scss";

const ShowLandings = () => {

    const navigate = useNavigate();
    const locations = useLocation().search;
    const myQueryString = queryString.parse(locations);

    const data = useContext(myUserDataContext);
    const { userData, isLogin , mainUrl , keySearch } = data;
    const { token } = userData;

    const [AllLandings, setAllLandings] = useState([]);
    const [isLoad , setIsLoad] = useState(true);
    const [metaPages, setMetaPage] = useState({});
    const [UpdateListLandings, setUpdateListLandings] = useState(false);



    //for make queries
    let joinqueries = [];
    for(let item in myQueryString){

        if(item === "page") continue ;
        const temp = `${item}=${myQueryString[item]}`;
        joinqueries.push(temp);
        
    }
    joinqueries = joinqueries.join("&");

    

   
    //get all Landings
    useEffect(() => {

        setIsLoad(true);
        axios.get(`/landing/index?page=${myQueryString.page}&relations[]=template&relations[]=user${joinqueries !== "" ? `&${joinqueries}` : ""}`, {

            headers: {

                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => {
                
                if (res.data.status) {


                    setAllLandings(res.data.data);
                    setIsLoad(false);
                    setMetaPage(res.data.meta)
                }
            })
            .catch(error => {

                responseHandler(error, "error");
            })

    }, [UpdateListLandings , keySearch]);







    //for delete Template
    const deleteTemplateHandler = (id) => {

       
        axios
            .delete(`landing/delete/${id}`, {

                headers: {

                    'Authorization': `Bearer ${token}`
                }
            })
            .then(res => {

                responseHandler(res, "succ");
                if (res.data.status) {

                    
                    setUpdateListLandings(!UpdateListLandings);
                    navigate(`/dashboard/showLandings`)
                }
            })
            .catch(error => {

                responseHandler(error, "error");
            })


    }

  

    

    //for click on any of paginations
    const paginationHandler = (url) => {

       
       setIsLoad(true);
        axios.get(`/landing/index?page=${url}&relations[]=template&relations[]=user${joinqueries !== "" ? `&${joinqueries}` : ""}`, {

            headers: {

                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => {
                
                if (res.data.status) {


                    setAllLandings(res.data.data);
                    setIsLoad(false);
                    setMetaPage(res.data.meta)
                }
            })
            .catch(error => {

                responseHandler(error, "error");
            })

    }

    if (AllLandings.length) {

        var length = metaPages.links.length;
    }
    const myPath = "/dashboard/showLandings";

   


    return (

        !isLoad && isLogin ?

            AllLandings.length ?
        
            <>

                <div className={styles.DivShowLandings}>

                    <div className={styles.TopOfTable}>

                        <span className={styles.InfoCountTable}>{`نمایش ${metaPages.from} تا ${metaPages.to} از ${metaPages.total} مورد`}</span>
                        <Link to="/dashboard/showLandings/trash-list"><button className='btn btn-danger btn-sm'><i className="bi bi-trash3-fill ms-1"></i><span>سطل زباله</span></button></Link>


                    </div>



                    <div className="table-responsive mx-2"  >
                        <table className="table table-bordered table-striped" >

                            <thead>

                                <tr>

                                    <th>ردیف</th>
                                    <th>نام لندینگ</th>
                                    <th>تعداد بازدید</th>
                                    <th>نام قالب</th>
                                    <th>آدرس</th>
                                    <th>سازنده لندینگ</th>
                                    <th>عملیات</th>

                                </tr>

                            </thead>


                            <tbody>

                                
                                {AllLandings.map((item, index) => {

                                    
                                    return <tr key={index}>

                                        <td>{metaPages.from + index}</td>
                                        <td>{item.title}</td>
                                        <td><Link to={`/dashboard/visits?landing_id=${item.id}`}>{item.statistics_count}</Link></td>
                                        <td>{item.template?.title ?? "حذف شده"}</td>
                                        <td><Link to={`${mainUrl}${item.slug}`} target='_blank'>{item.slug}</Link></td>
                                        <td>{item.user === null ? "حذف شده":`${item.user.first_name} ${item.user.last_name}`}</td>


                                        <td>

                                            <Link to={`/dashboard/showLandings/edite/${item.id}`} style={{ color: "#fff" }}><button className="btn btn-dark btn-sm mx-1">ویرایش</button></Link>
                                            <Link to={`/dashboard/showLandings/${item.id}`} style={{ color: "#fff" }}><button className="btn btn-dark btn-sm mx-1">جزئیات</button></Link>
                                            <Link to={`/dashboard/showLandings/newofcopy/${item.id}`} style={{ color: "#fff" }}><button className="btn btn-dark btn-sm mx-1">ایجاد کپی</button></Link>
                                            <button className='btn btn-danger btn-sm mx-1' data-bs-toggle="modal" data-bs-target={`#iam${index}`}>حذف</button>


                                            <ModalYesorNo idModal={`iam${index}`} doBtn="حذف" text={`لندینگ ${item.title} حذف شود؟`} Click={() => deleteTemplateHandler(item.id)} />


                                        </td>

                                    </tr>
                                })}



                            </tbody>


                        </table>



                    </div>

                    
                    {length !== 3 && <MyPagination meta={metaPages} myPath={myPath} paginationHandler={paginationHandler} joinqueries={joinqueries}/>}



                </div>

            </>

            :

            <ShowNothing text="لندینگی برای نمایش وجود ندارد" />

            :
            <Loading color="#7545fb" />
    );
};

export default ShowLandings;