import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';

//dependencys
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Routes, Route, Navigate} from "react-router-dom";
import { useStateWithCallbackLazy } from 'use-state-with-callback';

//components
import LoginPage from './components/LoginPage';
import Page404 from './components/404/Page404';
import Dashboard from './components/Dashboard';

//Pages
import ForShowDash from './components/Pages/ForShowDash';
import Users from './components/Pages/Users';
import EditeUser from './components/Pages/EditeUser';
import ShowTemplates from './components/Pages/ShowTemplates';
import InstallTemplate from './components/Pages/InstallTemplate';
import EditeTemplate from './components/Pages/EditeTemplate';
import DetailTemplate from './components/Pages/DetailTemplate';
import DetailLanding from './components/Pages/DetailLanding';
import Profile from './components/Pages/Profile';
import ShowLandings from './components/Pages/ShowLandings';
import InstallLanding from './components/Pages/InstallLanding';
import EditeLanding from './components/Pages/EditeLanding';
import CopyLanding from './components/Pages/CopyLanding';
import TrashList from './components/Pages/TrashList';
import ShowStatistics from './components/Pages/ShowStatistics';
import DetailStatistics from './components/Pages/DetailStatistics';
import RolesPage from './components/Pages/RolesPage';
import EditeRole from './components/Pages/EditeRole';

//shared
import ContentDashboard from './shared/ContentDashboard';
import { responseHandler } from './functions/responseHandler';



//context
export const myUserDataContext = React.createContext();

const App = () => {

  //for useeffect dependency in dashboard for update localStroage
  const [UpdateLocalStorage, setUpdateLocalStorage] = useState(false);


  // for check user login in past or not
  const pastLogin = JSON.parse(localStorage.getItem("isLogin"));
  const pastUserData = JSON.parse(localStorage.getItem("userData"));

  const [userData, setUserData] = useStateWithCallbackLazy(pastUserData === null ? {} : pastUserData);
  const [isLogin, setIsLogin] = useStateWithCallbackLazy(pastLogin === null ? false : pastLogin);

  //for search in hole of dashboard
  const [keySearch , setKeySearch] = useState(false);



  const baseUrl = document.getElementById("baseUrl").value;


  //for show Landing Link in showLandings
  // const mainUrl = "https://landing.getz.ir/";
  const mainUrl = baseUrl;
  // const mainUrl = "https://ir.getz.ir/";
  // const mainUrl = "http://127.0.0.1:8000/";
  // const mainUrl = "http://192.168.23.34:8000/";




  return (

    <myUserDataContext.Provider value={{ userData, setUserData, isLogin, setIsLogin, UpdateLocalStorage, setUpdateLocalStorage, mainUrl , keySearch , setKeySearch }}>

      <div>

        <Routes>

          <Route path="/" element={<LoginPage setUserData={setUserData} setIsLogin={setIsLogin} isLogin={isLogin} />} />
          <Route path='/dashboard' element={<Dashboard />} >

            <Route path="/dashboard" element={<ContentDashboard title="داشبورد" content={<ForShowDash />} />} />

            <Route path="profile" element={<ContentDashboard title="پروفایل" content={<Profile />} />} />

            <Route path='users/trash-list' element={<ContentDashboard title="سطل زباله کاربران" content={<TrashList show="user" />} />} />
            <Route path='users/edite/:id' element={<ContentDashboard title="ویرایش کاربر" content={<EditeUser />} />} />
            <Route path='users' element={<ContentDashboard title="کاربران" content={<Users />} />} />


            <Route path='installTemplate' element={<ContentDashboard title="نصب قالب جدید" content={<InstallTemplate />} />} />

            <Route path='showTemplates/trash-list' element={<ContentDashboard title="سطل زباله قالب ها" content={<TrashList show="template" />} />} />
            <Route path='showTemplates/:id' element={<ContentDashboard title="جزئیات قالب" content={<DetailTemplate />} />} />
            <Route path='showTemplates/edite/:id' element={<ContentDashboard title="ویرایش قالب" content={<EditeTemplate />} />} />
            <Route path='showTemplates' element={<ContentDashboard title="نمایش قالب ها" content={<ShowTemplates />} />} />


            <Route path='showLandings/trash-list' element={<ContentDashboard title="سطل زباله لندینگ ها" content={<TrashList show="landing" />} />} />
            <Route path='showLandings/:id' element={<ContentDashboard title="جزئیات لندینگ" content={<DetailLanding />} />} />
            <Route path='showLandings/newofcopy/:id' element={<ContentDashboard title="ایجد لندینگ کپی" content={<CopyLanding />} />} />
            <Route path='showLandings/edite/:id' element={<ContentDashboard title="ویرایش لندینگ" content={<EditeLanding />} />} />
            <Route path='showLandings/installLanding/:id' element={<ContentDashboard title="راه اندازی لندینگ" content={<InstallLanding />} />} />
            <Route path='showLandings' element={<ContentDashboard title="نمایش لندینگ ها" content={<ShowLandings />} />} />

            
            <Route path='roles/edite/:id' element={<ContentDashboard title="ویرایش نقش" content={<EditeRole />} />} />
            <Route path='roles' element={<ContentDashboard title="مدیریت نقش ها" content={<RolesPage />}/>} />



            <Route path='visits/:id' element={<ContentDashboard title="جزئیات آمار" content={<DetailStatistics />} />} />
            <Route path='visits' element={<ContentDashboard title="آمار" content={<ShowStatistics />} />} />

          </Route>
          {/* <Route path='/notfound' element={<Page404 />} /> */}
          <Route path='/*' element={<Page404 />} />

        </Routes>

      </div>

      <ToastContainer rtl />

    </myUserDataContext.Provider>


  );
};

export default App;