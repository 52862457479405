
import React from 'react';

const ModalYesorNo = ({ idModal , doBtn , color , text , Click}) => {




    return (

        <>


            <div className="modal fade" id={idModal} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"  >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {text}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-secondary" data-bs-dismiss="modal" >انصراف</button>
                            <button type="button" className={`btn btn-sm btn-${color === undefined ? "danger" : color}`} data-bs-dismiss="modal" onClick={() => Click()} >{doBtn}</button>
                        </div>
                    </div>
                </div>
            </div>





        </>
    );
};

export default ModalYesorNo;