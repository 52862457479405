import React, { useState, useContext, useRef } from 'react';


//styles
import styles from "./InstallTemplate.module.scss";
import copyStyleNewUser from "../../shared/NewUserModal.module.scss";

//functions
import { changeFaToEn } from '../../functions/changeFaToEn';
import { responseHandler } from '../../functions/responseHandler';
import { notify } from '../../functions/toasts';


//dependency
import { useNavigate } from 'react-router-dom';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import axios from 'axios';

//context
import { myUserDataContext } from '../../App';



const InstallTemplate = () => {

    const navigate = useNavigate();

    const data = useContext(myUserDataContext);
    const { userData , isLogin } = data;
    const { token } = userData;
        

    //for control id for parameters and group id
    const [counterGroup, setCounterGroup] = useStateWithCallbackLazy(1);
    const [counterPara, setCounterPara] = useStateWithCallbackLazy(1);



    //just for handle groups and parameters
    const [objGroupAndParameters, setObjGroupAndParameters] = useState([

        {

            groupId: 1,
            groupName: "",
            parameters: [

                {
                    paraId: 1,
                    paraName: "",
                    paraType: "text",
                    paraDescription: "",

                }



            ]
        }


    ])





    // const formData = new FormData();
    const [checkBtnInstall, setCheckBtnInstall] = useState(false);
    const [dataNewTemplate, setDataNewTemplate] = useStateWithCallbackLazy({

        title: "",
        slug: "",
        description: "",
        status: "active",
        parameters: [],
        zip: null,
    });




    //for inputs handler
    const inputHandler = (e) => {

        const first = changeFaToEn(e.target.value);
        setDataNewTemplate({ ...dataNewTemplate, [e.target.name]: first })

    }

    //for checks handler
    const checksHandler = (e) => {

        let totalCheck;
        e.target.checked ? totalCheck = "active" : totalCheck = "de_active";
        setDataNewTemplate({ ...dataNewTemplate, [e.target.name]: totalCheck })


    }

    //for choose btn
    const chooseFileHandler = (e) => {

        setDataNewTemplate({ ...dataNewTemplate, zip: e.target.files[0] })

    }

   
    const installHandler = () => {

        setCheckBtnInstall(true);

        const { title, slug, description, status, parameters, zip } = dataNewTemplate;


        if (title === "") {


            notify("نام قالب نمی‌تواند خالی باشد", "error");
            setCheckBtnInstall(false);
            return;
        }

        if (slug === "") {

            notify("آدرس نمی‌تواند خالی باشد", "error");
            setCheckBtnInstall(false);
            return;
        }


        if (zip === undefined || zip === null) {


            notify("فایلی انتخاب نشده است", "error");
            setCheckBtnInstall(false);
            return;
        }


        if (objGroupAndParameters[0].groupName === "" && objGroupAndParameters.length === 1) {

            notify("پارامتری ایجاد نکرده اید", "error")
            setCheckBtnInstall(false);
            return;

        }


        for (let item of objGroupAndParameters) {

            if (item.groupName === "") {

                notify("هیچ گروهی نمی‌تواند بدون نام باشد", "error", 2500);
                setCheckBtnInstall(false);
                return;
            }

        }

        for (let item of objGroupAndParameters) {

            for (let item2 of item.parameters) {

                if (item2.paraName === "") {

                    notify("هیچ پارامتری از هیچ گروهی نباید بدون نام باشد", "error", 2500)
                    setCheckBtnInstall(false);
                    return;
                }
            }
        }

       

        setDataNewTemplate({ ...dataNewTemplate, parameters: [...objGroupAndParameters] }, (nowState) => {

            const formData = new FormData();
            

            formData.append('title', title);
            formData.append('slug', slug);
            formData.append('description', description);
            formData.append('status', status);
            formData.append('parameters', JSON.stringify(nowState.parameters));
            formData.append('zip', zip);
            formData.append('user_id', userData.user.id);
           

            axios
            .post("/template/create", formData, {

                headers: {

                    'Authorization': `Bearer ${token}`
                }
            })
            .then(res => {

                responseHandler(res, "succ");
                if (res.data.status) {

                    navigate("/dashboard/showTemplates");
                }
                setCheckBtnInstall(false);
            })
            .catch(error => {

                responseHandler(error, "error");
                setCheckBtnInstall(false);

            })
        });


        


       


    }






   
    const handleAddGroup = () => {

        let GroupDivObj;
        setCounterGroup(prev => prev + 1, (nowCounterGroup) => {

            GroupDivObj = {

                groupId: nowCounterGroup,
                groupName: "",
                parameters: [

                    {
                        paraId: 1,
                        paraName: "",
                        paraType: "text",
                        paraDescription: "",

                    }



                ]
            }

            setObjGroupAndParameters([...objGroupAndParameters, GroupDivObj])
        })

    };



    const deleteGroupHandler = (e, delGroupId) => {

        const res = window.confirm("گروه مورد نظر حذف شود ؟");
        if (res) {

            const findIndex = objGroupAndParameters.findIndex(item => item.groupId == delGroupId);
            objGroupAndParameters.splice(findIndex, 1);
            setObjGroupAndParameters([...objGroupAndParameters]);

        }

    }


    const InputOfGroupsHandler = (e, changeGroupId) => {

        const findIndex = objGroupAndParameters.findIndex(item => item.groupId === changeGroupId);

       
        const first = changeFaToEn(e.target.value);

        objGroupAndParameters[findIndex].groupName = first;
        setObjGroupAndParameters([...objGroupAndParameters])

    }


    ///////////////////////////////////////////////////////////////////////




    const addParameterHandler = (myGroupId) => {

        let ParaDiv;
        setCounterPara(prev => prev + 1, (nowCounterPara) => {

            ParaDiv = {
                paraId: nowCounterPara,
                paraName: "",
                paraType: "text",
                paraDescription: "",

            }

            const findIndex = objGroupAndParameters.findIndex(item => item.groupId === myGroupId);

            objGroupAndParameters[findIndex].parameters.push(ParaDiv);
            setObjGroupAndParameters([...objGroupAndParameters]);



        })
    }


    const deleteParaHandler = (e, myGroupID, delParaId) => {

        const findGroup = objGroupAndParameters.findIndex(item => item.groupId === myGroupID);

        const paraIndex = objGroupAndParameters[findGroup].parameters.findIndex(item => item.paraId === delParaId);



        objGroupAndParameters[findGroup].parameters.splice(paraIndex, 1);
        setObjGroupAndParameters([...objGroupAndParameters])



    }


    const ParaInputHandler = (e, changeGroupId, changeParaId) => {

        const findGroup = objGroupAndParameters.findIndex(item => item.groupId === changeGroupId);

        const paraIndex = objGroupAndParameters[findGroup].parameters.findIndex(item => item.paraId === changeParaId);

        
        const first = changeFaToEn(e.target.value);

        objGroupAndParameters[findGroup].parameters[paraIndex][e.target.name] = first;
        setObjGroupAndParameters([...objGroupAndParameters])



    }








    return (

        isLogin &&
        <>
            <div className={styles.installTemplateDiv}>


                <div className={styles.InputsDiv}>

                    <div className={copyStyleNewUser.Inputs}>
                        <label htmlFor='nameTemplateInstall'>نام قالب:</label>
                        <input type='text' id="nameTemplateInstall" name="title" value={dataNewTemplate.title} onChange={inputHandler} />
                    </div>


                    <div className={copyStyleNewUser.Inputs}>
                        <label htmlFor='installSlug'>آدرس:</label>
                        <input type='text' id="installSlug" name="slug" value={dataNewTemplate.slug} onChange={inputHandler} />
                    </div>


                    <div className={copyStyleNewUser.Inputs}>
                        <label htmlFor='descriptionInstall'>توضیحات (اختیاری):</label>
                        <textarea type='text' id="descriptionInstall" name="description" value={dataNewTemplate.description} onChange={inputHandler} rows={6} />
                    </div>


                    <div className={styles.Divchecks}>

                        <div className={styles.checks}>


                            <div className="mb-3">
                                <input className="form-control" type="file" id="formFile" accept='.zip' onChange={chooseFileHandler} />
                            </div>


                            <label className={copyStyleNewUser.conCehcks}>فعال
                                <input type="checkbox" name="status" checked={dataNewTemplate.status === "active" ? true : false} onChange={checksHandler} />
                                <span className={copyStyleNewUser.checkmark}></span>
                            </label>

                        </div>



                    </div>

                </div>



                <div className={styles.parameter}>

                    <p>

                        <a className="btn btn-primary" data-bs-toggle="collapse" href="#containerParameter" role="button" aria-expanded="false" aria-controls="collapseExample">
                            پارامترها <i className="bi bi-ui-checks-grid"></i>
                        </a>

                    </p>


                    <div className={`collapse`} id="containerParameter">


                        <button className={`${styles.btnPlusGroup} btn btn-sm btn-warning mb-2`} onClick={handleAddGroup}>گروه<i className="bi bi-plus-lg me-1"></i></button>



                        {objGroupAndParameters.map((item, index) => {

                            return <div key={item.groupId} className={`${styles.Groups} card card-body mb-3`}>



                                <div className={styles.groupNameDiv}>

                                    <input type="text" name="groupName" placeholder='نام گروه' className={styles.namePara} value={item.groupName} onChange={(e) => InputOfGroupsHandler(e, item.groupId)} />
                                    <button className={`${styles.btnPlusPara} btn btn-warning btn-sm`} onClick={() => addParameterHandler(item.groupId)}><span>پارامتر</span><i className="bi bi-plus-lg me-1"></i></button>
                                    <button className={`${styles.btnDeleteGroup} btn btn-danger btn-sm`} onClick={(e) => deleteGroupHandler(e, item.groupId)}><span>حذف گروه</span><i className="bi bi-trash3-fill me-1"></i></button>

                                </div>


                                {objGroupAndParameters[index].parameters.map((paraItem, paraIndex) => {

                                    return <div key={paraItem.paraId} className={styles.myParameterOfGroup} >


                                        <i className={`${styles.deletePara} bi bi-x-circle-fill`} onClick={(e) => deleteParaHandler(e, item.groupId, paraItem.paraId)}></i>


                                        <div className={styles.nameParaDiv}>

                                            <input type="text" name="paraName" placeholder='نام پارامتر' className={styles.namePara} value={paraItem.paraName} onChange={(e) => ParaInputHandler(e, item.groupId, paraItem.paraId)} />


                                        </div>


                                        <div className={styles.keyandValueDiv}>

                                            <div className={styles.myKey}>

                                                <span>تایپ:</span>
                                                <select className="me-2" name="paraType" value={paraItem.paraType} onChange={(e) => ParaInputHandler(e, item.groupId, paraItem.paraId)}>

                                                    <option value="text">متن</option>
                                                    <option value="color">رنگ</option>
                                                    <option value="number">عدد</option>
                                                    <option value="password">پسورد</option>
                                                    <option value="checkbox">چک باکس</option>
                                                    <option value="date">تاریخ</option>
                                                    <option value="select">موضوع و لیبل</option>


                                                </select>

                                            </div>

                                            <div className={styles.myKey}>

                                                <span className="me-2">توضیحات:</span>
                                                <input className="me-2" type="text" name="paraDescription" value={paraItem.paraDescription} onChange={(e) => ParaInputHandler(e, item.groupId, paraItem.paraId)} />

                                            </div>


                                        </div>


                                    </div>

                                })}




                            </div>


                        })}


                    </div>



                </div>




                <div className={`${styles.btns}`}>

                    <button className='btn btn-primary mx-1' disabled={checkBtnInstall} onClick={installHandler}>
                        نصب
                        {checkBtnInstall && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
                    </button>
                    <button className='btn btn-dark mx-1' onClick={() => navigate("/dashboard/showTemplates")}>قالب های نصب شده</button>
                </div>


            </div>
        </>
    );
};

export default InstallTemplate;