import React, { useEffect, useContext, useState } from 'react';

//styles
import styles from "./EditeLanding.module.scss";
import copyOfInstallLanding from "./InstallLanding.module.scss";
import copyOFNewUserModal from "../../shared/NewUserModal.module.scss";

//components
import Loading from '../../shared/Loading';

//dependencys
import { useNavigate, useParams } from 'react-router-dom';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import axios from 'axios';



//contexts
import { myUserDataContext } from '../../App';

//functions
import { responseHandler } from '../../functions/responseHandler';
import { changeFaToEn } from '../../functions/changeFaToEn';
import { notify } from '../../functions/toasts';


const CopyLanding = () => {

    const navigate = useNavigate();
    const params = useParams();

    const data = useContext(myUserDataContext);
    const { userData, isLogin } = data;
    const { token } = userData;

    //a copy of template Data in database
    const [copyOfData, setCopyOfData] = useState({});

    //for get template data for redner
    const [dataOfTemplate, setDataOfTemplate] = useState([]);
    const [templateId, setTemplateId] = useState("");

    //for selecr subject for panel
    const [selectOptions, setSelectOptions] = useState({ subject: [], label: [] });

    const [checkBtnCopy, setCheckBtnCopy] = useState(false);
    const [editeLanding, setEditeLanding] = useStateWithCallbackLazy({});
    const [isLoad, setIsLoad] = useState(true);

    const [keyupdateLanding, setKeyupdateLanding] = useState(false);


    //for get optionValue from panel
    const getSelectOptions = () => {

        setIsLoad(true);
        axios
            .get("/landing/get-landing-subjects", {

                headers: {

                    'Authorization': `Bearer ${token}`,
                }
            })
            .then(res => {

                if (res.data.status) {

                    setSelectOptions({ subject: res.data.subjects, label: res.data.labels })
                    setIsLoad(false);

                } else {

                    responseHandler(res, "error");
                }

            })
            .catch(error => {

                responseHandler(error, "error");
            })
    }




    useEffect(() => {


        axios
            .get(`/landing/show/${params.id}`, {

                headers: {

                    'Authorization': `Bearer ${token}`,
                }
            })
            .then(async res => {

                responseHandler(res, "succ", false);
                if (res.data.status) {

                    const { title, slug, parameters } = res.data.data;

                    setEditeLanding({

                        title: "",
                        slug: "",
                        description: "",
                        parameters: JSON.parse(parameters),
                        template_id: "",

                    })

                    setCopyOfData({

                        title: title,


                    });


                    await axios
                        .get(`/template/show/${res.data.data.template_id}`, {

                            headers: {

                                'Authorization': `Bearer ${token}`,
                            }
                        })
                        .then(res2 => {

                            if (res2.data.status) {

                                setTemplateId(res2.data.data.id)
                                setDataOfTemplate([...JSON.parse(res2.data.data.parameters)]);
                                setIsLoad(false);
                            }

                        })
                        .catch(error2 => {

                            responseHandler(error2, "error");

                        })

                    getSelectOptions();


                }
            })
            .catch(error => {

                responseHandler(error, "error");
                if (error.response?.status === 404) navigate("/notfound", { replace: true })
            })


    }, [keyupdateLanding])






    const inputHandler = (e) => {


        if (e.target.name === "title") {

            const firstTitle = changeFaToEn(e.target.value);
            setEditeLanding({ ...editeLanding, title: firstTitle, parameters: { ...editeLanding.parameters, baseAndMainTitle: firstTitle } })
            return;


        }

        const first = changeFaToEn(e.target.value);
        setEditeLanding({ ...editeLanding, [e.target.name]: first })

    }


    const inputParaHandler = (e) => {

        let first = changeFaToEn(e.target.value);
        setEditeLanding({ ...editeLanding, parameters: { ...editeLanding.parameters, [e.target.name]: first } })
    }




    //for CopyHandler
    const CopyLandingHandler = () => {

        setCheckBtnCopy(true);


        if (editeLanding.title === "") {

            notify("عنوان لندینگ نمی‌تواند خالی باشد", "error");
            setCheckBtnCopy(false);
            return;

        }

        if (editeLanding.parameters.labelSelect !== "" && editeLanding.parameters.subjectSelect === "") {

            notify("در صورت انتخاب برچسب باید موضوع را هم انتخاب کرده باشید", "error", 2000);
            setCheckBtnCopy(false);
            return;

        }

        if (editeLanding.parameters.subjectSelect !== "" && editeLanding.parameters.type === "") {

            notify("اگر موضوع انتخاب کرده باشید باید تایپ هم داشته باشید", "error", 2000);
            setCheckBtnCopy(false);
            return;

        }


        if (editeLanding.parameters.subjectSelect !== "" && editeLanding.parameters.token === "") {

            notify("اگر موضوع انتخاب کرده باشید باید توکن هم داشته باشید", "error", 2000);
            setCheckBtnCopy(false);
            return;

        }




        const formData = new FormData();


        formData.append('title', editeLanding.title);
        formData.append('slug', editeLanding.slug);
        formData.append('description', editeLanding.description);
        formData.append('parameters', JSON.stringify({ ...editeLanding.parameters, baseAndMainTitle: editeLanding.title, subjectSelect: editeLanding.parameters.subjectSelect, labelSelect: editeLanding.parameters.labelSelect }));
        formData.append('template_id', templateId);
        formData.append('user_id', userData.user.id);



        if (editeLanding.parameters.subjectSelect !== "") {

            const bodyPanel = {

                landing_token: editeLanding.parameters.token,
                type: editeLanding.parameters.type,
                subject: editeLanding.parameters.subjectSelect,
                ...(editeLanding.parameters.labelSelect !== "" && { label: editeLanding.parameters.labelSelect }),
            }


            axios
                .post(`/landing/send-landing-subjects`, bodyPanel, {

                    headers: {

                        'Authorization': `Bearer ${token}`,
                    }
                })
                .then(res => {

                    if (res.data.status) {

                        //for install
                        axios
                            .post("/landing/create", formData, {

                                headers: {

                                    'Authorization': `Bearer ${token}`,
                                }
                            })
                            .then(res => {

                                responseHandler(res, "succ");
                                if (res.data.status) {

                                    navigate("/dashboard/showLandings");
                                }
                               setCheckBtnCopy(false);;
                            })
                            .catch(error => {

                                responseHandler(error, "error");
                               setCheckBtnCopy(false);
                            })
                    }
                    else {

                        notify("توکن وب سرویس وارد شده اشتباه است", "error");
                       setCheckBtnCopy(false);

                    }
                })
                .catch(error => {

                    responseHandler(error, "error");
                })
        }
        else {

            //for install
            axios
                .post("/landing/create", formData, {

                    headers: {

                        'Authorization': `Bearer ${token}`,
                    }
                })
                .then(res => {

                    responseHandler(res, "succ");
                    if (res.data.status) {

                        navigate("/dashboard/showLandings");
                    }
                   setCheckBtnCopy(false);;
                })
                .catch(error => {

                    responseHandler(error, "error");
                   setCheckBtnCopy(false);
                })
        }
    }





    return (
        !isLoad && isLogin ?
            <>

                <div className={styles.copyLandingDiv}>


                    <div className={copyOfInstallLanding.title}>

                        <span>{`کپی از لندینگ ${copyOfData.title}`}</span>
                        <i className="bi bi-clipboard2-fill"></i>

                    </div>

                    <div className={copyOfInstallLanding.consts}>


                        <div className={copyOFNewUserModal.Inputs}>
                            <label htmlFor='EditetitleLanding'>عنوان لندینگ:</label>
                            <input type='text' id="EditetitleLanding" name="title" value={editeLanding.title} onChange={inputHandler} />
                        </div>


                        <div className={copyOFNewUserModal.Inputs}>
                            <label htmlFor='EditeslugLanding'>آدرس لندینگ:</label>
                            <input type='text' id="EditeslugLanding" name="slug" value={editeLanding.slug} onChange={inputHandler} />
                        </div>


                    </div>


                    <div className={copyOfInstallLanding.titleTopAccordion}>

                        <span>پارامتر ها</span>

                    </div>

                    <div className="accordion " id="accordionExample">

                        {dataOfTemplate.map((item, index) => {

                            return <React.Fragment key={item.groupId}>

                                <div className="accordion-item">

                                    <h2 className="accordion-header">

                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${item.groupId}`} aria-expanded="true" aria-controls="collapseOne">
                                            {item.groupName}
                                        </button>

                                    </h2>


                                    <div id={`collapse${item.groupId}`} className={`accordion-collapse collapse ${index + 1 === dataOfTemplate.length && 'show'}`} >

                                        <div className={`accordion-body ${copyOfInstallLanding.bodyOfAccordion}`}>


                                            {item.parameters.map((paraItem, paraIndex) => {

                                                return <div key={`${item.groupId}${paraItem.paraId}`}>


                                                    <div className={copyOfInstallLanding.ParaDiv}>

                                                        <div className={copyOfInstallLanding.toptext}>

                                                            <label className={copyOfInstallLanding.ParaName} htmlFor={`${item.groupId}${paraItem.paraId}`}>{paraItem.paraName}</label>
                                                            <span className={copyOfInstallLanding.paraDec}>{paraItem.paraDescription}</span>

                                                        </div>

                                                        {paraItem.paraType !== "select" ?

                                                            <input type={paraItem.paraType} id={`${item.groupId}${paraItem.paraId}`} name={paraItem.paraName} value={editeLanding.parameters[paraItem.paraName]} onChange={inputParaHandler} />

                                                            :

                                                            <select className={`${styles.SelectPanel} form-select`} name={`${paraItem.paraName}Select`} value={editeLanding.parameters[`${paraItem.paraName}Select`]} onChange={inputParaHandler}>


                                                                <option value="" >انتخاب نشده</option>
                                                                {selectOptions[`${paraItem.paraName}`].map((itemOp, indexOp) => {

                                                                    return <option key={indexOp} value={itemOp.id} >{itemOp.name}</option>

                                                                })}


                                                            </select>

                                                        }

                                                    </div>


                                                </div>

                                            })}







                                        </div>

                                    </div>

                                </div>



                            </React.Fragment>

                        })}

                    </div>


                    <div className={`${copyOfInstallLanding.btns} mt-5 mb-4`}>

                        <button className='btn btn-primary ms-2' disabled={checkBtnCopy} onClick={CopyLandingHandler} >
                            ایجاد کپی
                            {checkBtnCopy && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
                        </button>
                        <button className='btn btn-secondary' onClick={() => navigate("/dashboard/showLandings")}>بازگشت</button>
                    </div>


                </div>

            </>

            :

            <Loading color="#7545fb" />

    );
};

export default CopyLanding;