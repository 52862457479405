import React, { useEffect, useContext, useState } from 'react';

//styles
import styles from "./EditeTemplate.module.scss";
import copyStyleNewUser from "../../shared/NewUserModal.module.scss";
import copyStyleInstallTemplate from "./InstallTemplate.module.scss";


//components
import Loading from '../../shared/Loading';


//dependencys
import { useNavigate, useParams } from 'react-router-dom';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import axios from 'axios';


//contexts
import { myUserDataContext } from '../../App';

//functions
import { responseHandler } from '../../functions/responseHandler';
import { changeFaToEn } from '../../functions/changeFaToEn';
import { notify } from '../../functions/toasts';





const EditeTemplate = () => {


    const navigate = useNavigate();
    const params = useParams();

    const data = useContext(myUserDataContext);
    const { userData, isLogin } = data;
    const { token } = userData;


    //just for handle groups and parameters
    const [objGroupAndParameters, setObjGroupAndParameters] = useState([])




    //a copy of template Data in database
    const [copyOfData, setCopyOfData] = useState({});

    const formData = new FormData();
    const [checkBtnEdite, setCheckBtnEdite] = useState(false);
    const [editeTemplate, setEditeTemplate] = useStateWithCallbackLazy({});
    const [isLoad, setIsLoad] = useState(true);

    const [keyupdateTemplate, setKeyupdateTemplate] = useState(false);

    useEffect(() => {


        axios
            .get(`/template/show/${params.id}`, {

                headers: {

                    'Authorization': `Bearer ${token}`,
                }
            })
            .then(res => {

                responseHandler(res, "succ", false);
                if (res.data.status) {

                    const { title, slug, description, status, parameters, zip } = res.data.data;

                    setEditeTemplate({

                        title: title,
                        slug: slug,
                        description: description,
                        status: status,
                        parameters: parameters,
                        zip: zip
                    })

                    setCopyOfData({

                        title: title,
                        slug: slug,
                        description: description,
                        status: status,
                        parameters: parameters,
                        zip: zip

                    });

                    const parseParameters = JSON.parse(parameters)
                    setObjGroupAndParameters([...parseParameters]);

                    setIsLoad(false);

                }
            })
            .catch(error => {

                responseHandler(error, "error");
                if (error.response?.status === 404) navigate("/notfound", { replace: true })
            })




    }, [keyupdateTemplate])





    //for inputs handler
    const inputHandler = (e) => {


        const first = changeFaToEn(e.target.value);
        setEditeTemplate({ ...editeTemplate, [e.target.name]: first })

    }

    //for checks handler
    const checksHandler = (e) => {

        let totalCheck;
        e.target.checked ? totalCheck = "active" : totalCheck = "de_active";
        setEditeTemplate({ ...editeTemplate, [e.target.name]: totalCheck })


    }


    //for choose btn
    const chooseFileHandler = (e) => {

        setEditeTemplate({ ...editeTemplate, zip: e.target.files[0] })

    }




    const handleAddGroup = () => {
        const GroupDivObj = {
            groupId: objGroupAndParameters.length + 1,
            groupName: "",
            parameters: [
                {
                    paraId: 1,
                    paraName: "",
                    paraType: "text",
                    paraDescription: "",
                }
            ]
        };
        setObjGroupAndParameters([...objGroupAndParameters, GroupDivObj]);
    };



    const deleteGroupHandler = (e, delGroupId) => {

        const res = window.confirm("گروه مورد نظر حذف شود ؟");
        if (res) {

            const findIndex = objGroupAndParameters.findIndex(item => item.groupId == delGroupId);
            objGroupAndParameters.splice(findIndex, 1);
            setObjGroupAndParameters([...objGroupAndParameters]);

        }

    }


    const InputOfGroupsHandler = (e, changeGroupId) => {

        const findIndex = objGroupAndParameters.findIndex(item => item.groupId === changeGroupId);


        const first = changeFaToEn(e.target.value);

        objGroupAndParameters[findIndex].groupName = first;
        setObjGroupAndParameters([...objGroupAndParameters])

    }


    ///////////////////////////////////////////////////////////////////////



    const addParameterHandler = (myGroupId) => {

        const findIndex = objGroupAndParameters.findIndex(item => item.groupId === myGroupId);

        const ParaDiv = {
            paraId: objGroupAndParameters[findIndex].parameters.length + 1,
            paraName: "",
            paraType: "text",
            paraDescription: "",
        };

        objGroupAndParameters[findIndex].parameters.push(ParaDiv);
        setObjGroupAndParameters([...objGroupAndParameters]);
    };


    const deleteParaHandler = (e, myGroupID, delParaId) => {

        const findGroup = objGroupAndParameters.findIndex(item => item.groupId === myGroupID);

        const paraIndex = objGroupAndParameters[findGroup].parameters.findIndex(item => item.paraId === delParaId);



        objGroupAndParameters[findGroup].parameters.splice(paraIndex, 1);
        setObjGroupAndParameters([...objGroupAndParameters])



    }


    const ParaInputHandler = (e, changeGroupId, changeParaId) => {

        const findGroup = objGroupAndParameters.findIndex(item => item.groupId === changeGroupId);

        const paraIndex = objGroupAndParameters[findGroup].parameters.findIndex(item => item.paraId === changeParaId);


        const first = changeFaToEn(e.target.value);

        objGroupAndParameters[findGroup].parameters[paraIndex][e.target.name] = first;
        setObjGroupAndParameters([...objGroupAndParameters])



    }





    //for compare two array
    const compareArrays = (a, b) => {

        return JSON.stringify(a) === JSON.stringify(b);
    };


    //fot handle edite btn
    const EditeTemplateHandler = () => {

        setCheckBtnEdite(true);

        let bodySend = {};



        //use statewithCallBack and handle the changes in objGroupAndParameters 
        setEditeTemplate({ ...editeTemplate, parameters: [...objGroupAndParameters] }, (nowState) => {

            for (let item in copyOfData) {

                if (item === "parameters") {

                    if (compareArrays(copyOfData[item], JSON.stringify(nowState[item])) === false) bodySend[item] = nowState[item];
                }
                else {

                    if (copyOfData[item] !== nowState[item]) bodySend[item] = nowState[item];
                }


            }


            const keysBodySend = Object.keys(bodySend);
            if (keysBodySend.length === 0) {

                notify("هیچ موردی را برای ویرایش تغییر نداید", "error", 2000);
                setCheckBtnEdite(false);
                return;

            }


            const { title, slug, description, status, parameters, zip } = bodySend;

            if (title === "") {


                notify("نام قالب نمی‌تواند خالی باشد", "error");
                setCheckBtnEdite(false);
                return;
            }


            // if (zip === undefined || zip === null) {


            //     notify("فایلی انتخاب نشده است", "error");
            //     setCheckBtnEdite(false);
            //     return;
            // }


            if (parameters !== undefined && parameters[0].groupName === "" && parameters.length === 1) {

                notify("پارامتری ایجاد نکرده اید یا گروهی بدون نام است", "error", 2500)
                setCheckBtnEdite(false);
                return;

            }


            if (parameters !== undefined) {

                for (let item of parameters) {

                    if (item.groupName === "") {

                        notify("هیچ گروهی نمی‌تواند بدون نام باشد", "error", 2500);
                        setCheckBtnEdite(false);
                        return;
                    }

                }


                for (let item of parameters) {

                    for (let item2 of item.parameters) {

                        if (item2.paraName === "") {

                            notify("هیچ پارامتری از هیچ گروهی نباید بدون نام باشد", "error", 2500)
                            setCheckBtnEdite(false);
                            return;
                        }
                    }
                }


            }



            const _method = "put";
            for (let item in bodySend) {

                if (item === "parameters") formData.append(`${item}`, JSON.stringify(bodySend[item]));
                else formData.append(`${item}`, bodySend[item]);

            }


            formData.append('_method', _method);


            axios
                .post(`/template/update/${params.id}`, formData, {

                    headers: {

                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(res => {

                    responseHandler(res, "succ");
                    if (res.data.status) {

                        setIsLoad(true);
                        setKeyupdateTemplate(!keyupdateTemplate);

                    }
                    setCheckBtnEdite(false);
                })
                .catch(error => {

                    responseHandler(error, "error");
                    setCheckBtnEdite(false);

                })




        });






    }






    return (

        !isLoad && isLogin ?
            <>

                <div className={styles.EditeTemplateDiv}>

                    <div className={styles.title}>

                        <span>{`ویرایش قالب ${copyOfData.title}`}</span>
                        <i className="bi bi-aspect-ratio"></i>

                    </div>


                    <div className={copyStyleInstallTemplate.InputsDiv}>

                        <div className={copyStyleNewUser.Inputs}>
                            <label htmlFor='nameTemplateEdite'>نام قالب:</label>
                            <input type='text' id="nameTemplateEdite" name="title" value={editeTemplate.title} onChange={inputHandler} />
                        </div>


                        <div className={copyStyleNewUser.Inputs}>
                            <label htmlFor='descriptionEdite'>توضیحات (اختیاری):</label>
                            <textarea type='text' id="descriptionEdite" name="description" value={editeTemplate.description} onChange={inputHandler} rows={6} />
                        </div>


                        <div className={copyStyleInstallTemplate.Divchecks}>

                            <div className={copyStyleInstallTemplate.checks}>


                                <div className="mb-3">
                                    <input className="form-control" type="file" id="formFileEdite" accept='.zip' onChange={chooseFileHandler} />
                                </div>


                                <label className={copyStyleNewUser.conCehcks}>فعال
                                    <input type="checkbox" name="status" checked={editeTemplate.status === "active" ? true : false} onChange={checksHandler} />
                                    <span className={copyStyleNewUser.checkmark}></span>
                                </label>

                            </div>



                        </div>

                    </div>



                    <div className={copyStyleInstallTemplate.parameter}>

                        <p>

                            <a className="btn btn-primary" data-bs-toggle="collapse" href="#containerParameterEdite" role="button" aria-expanded="false" aria-controls="collapseExample">
                                پارامترها <i className="bi bi-ui-checks-grid"></i>
                            </a>

                        </p>


                        <div className={`collapse`} id="containerParameterEdite">


                            <button className={`${copyStyleInstallTemplate.btnPlusGroup} btn btn-sm btn-warning mb-2`} onClick={handleAddGroup}>گروه<i className="bi bi-plus-lg me-1"></i></button>



                            {objGroupAndParameters.map((item, index) => {

                              
                                    return <div key={item.groupId} className={`${copyStyleInstallTemplate.Groups} card card-body mb-3`}>



                                        <div className={copyStyleInstallTemplate.groupNameDiv}>

                                            <input type="text" name="groupName" placeholder='نام گروه' className={copyStyleInstallTemplate.namePara} value={item.groupName} onChange={(e) => InputOfGroupsHandler(e, item.groupId)} />
                                            <button className={`${copyStyleInstallTemplate.btnPlusPara} btn btn-warning btn-sm`} onClick={() => addParameterHandler(item.groupId)}><span>پارامتر</span><i className="bi bi-plus-lg me-1"></i></button>
                                            <button className={`${copyStyleInstallTemplate.btnDeleteGroup} btn btn-danger btn-sm`} onClick={(e) => deleteGroupHandler(e, item.groupId)}><span>حذف گروه</span><i className="bi bi-trash3-fill me-1"></i></button>

                                        </div>


                                        {objGroupAndParameters[index].parameters.map((paraItem, paraIndex) => {



                                            return <div key={paraItem.paraId} className={copyStyleInstallTemplate.myParameterOfGroup} >


                                                <i className={`${copyStyleInstallTemplate.deletePara} bi bi-x-circle-fill`} onClick={(e) => deleteParaHandler(e, item.groupId, paraItem.paraId)}></i>


                                                <div className={copyStyleInstallTemplate.nameParaDiv}>

                                                    <input type="text" name="paraName" placeholder='نام پارامتر' className={copyStyleInstallTemplate.namePara} value={paraItem.paraName} onChange={(e) => ParaInputHandler(e, item.groupId, paraItem.paraId)} />


                                                </div>


                                                <div className={copyStyleInstallTemplate.keyandValueDiv}>

                                                    <div className={copyStyleInstallTemplate.myKey}>

                                                        <span>تایپ:</span>
                                                        <select className="me-2" name="paraType" value={paraItem.paraType} onChange={(e) => ParaInputHandler(e, item.groupId, paraItem.paraId)}>

                                                            <option value="text">متن</option>
                                                            <option value="color">رنگ</option>
                                                            <option value="number">عدد</option>
                                                            <option value="password">پسورد</option>
                                                            <option value="checkbox">چک باکس</option>
                                                            <option value="date">تاریخ</option>
                                                            <option value="select">موضوع و لیبل</option>

                                                        </select>

                                                    </div>

                                                    <div className={copyStyleInstallTemplate.myKey}>

                                                        <span className="me-2">توضیحات:</span>
                                                        <input className="me-2" type="text" name="paraDescription" value={paraItem.paraDescription} onChange={(e) => ParaInputHandler(e, item.groupId, paraItem.paraId)} />

                                                    </div>


                                                </div>


                                            </div>

                                        })}




                                    </div>


                            })}


                        </div>



                    </div>




                    <div className={`${copyStyleInstallTemplate.btns}`}>

                        <button className='btn btn-primary mx-1' disabled={checkBtnEdite} onClick={EditeTemplateHandler} >
                            ویرایش
                            {checkBtnEdite && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
                        </button>
                        <button className='btn btn-dark mx-1' onClick={() => navigate(-1)}>بازگشت به قالب ها</button>
                    </div>


                </div>




            </>

            :

            <Loading color="#7545fb" />
    );
};

export default EditeTemplate;