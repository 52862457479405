import React, { useContext, useEffect, useState } from 'react';

//styles
import styles from "./ShowStatistics.module.scss";

//cotexts
import { myUserDataContext } from '../../App';

//dependences
import { useNavigate, useLocation, Link } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';

//functions
import { responseHandler } from '../../functions/responseHandler';
import { convertToPersianDate } from '../../functions/convertToPersianDate';

//shared
import Loading from '../../shared/Loading';
import ShowNothing from '../../shared/ShowNothing';
import MyPagination from '../../shared/MyPagination';



const ShowStatistics = () => {

    const navigate = useNavigate();
    const locations = useLocation().search;
    const myQueryString = queryString.parse(locations);

    const data = useContext(myUserDataContext);
    const { userData, isLogin } = data;
    const { token } = userData;

    const [allStatistics, setAllStatistics] = useState([]);
    const [isLoad, setIsLoad] = useState(true);
    const [metaPages, setMetaPage] = useState({});
    const [UpdateListStatistics, setUpdateListStatistics] = useState(false);


    //for make queries
    let joinqueries = [];
    for (let item in myQueryString) {

        if (item === "page") continue;
        const temp = `${item}=${myQueryString[item]}`;
        joinqueries.push(temp);

    }
    joinqueries = joinqueries.join("&");




    useEffect(() => {

        setIsLoad(true);
        axios
            .get(`/statistic/index?page=${myQueryString.page}&relations[]=landing${joinqueries !== "" ? `&${joinqueries}` : ""}`, {

                headers: {

                    'Authorization': `Bearer ${token}`,
                }
            })
            .then(res => {

                if (res.data.status) {

                    setAllStatistics(res.data.data);
                    setIsLoad(false);
                    setMetaPage(res.data.meta);

                }

            })
            .catch(error => {

                responseHandler(error, "error");
            })


    }, [UpdateListStatistics]);



    //for click on any of paginations
    const paginationHandler = (url) => {


        setIsLoad(true);
        axios.get(`/statistic/index?page=${url}&relations[]=landing${joinqueries !== "" ? `&${joinqueries}` : ""}`, {

            headers: {

                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => {

                if (res.data.status) {


                    setAllStatistics(res.data.data);
                    setIsLoad(false);
                    setMetaPage(res.data.meta)
                }
            })
            .catch(error => {

                responseHandler(error, "error");
            })

    }




    if (allStatistics.length) {

        var length = metaPages.links.length;
        
    }
    const myPath = "/dashboard/visits";



    return (
        !isLoad && isLogin ?

            allStatistics.length ?
                <>

                    <div className={styles.StatisticsDiv}>


                        <div className={styles.TopOfTable}>

                            <span className={styles.InfoCountTable}>{`نمایش ${metaPages.from} تا ${metaPages.to} از ${metaPages.total} مورد`}</span>

                        </div>

                        <div className="table-responsive mx-2"  >
                            <table className="table table-bordered table-striped" >

                                <thead>

                                    <tr>

                                        <th>ردیف</th>
                                        <th>IP کاربر</th>
                                        <th>مرورگر</th>
                                        <th>سیستم عامل</th>
                                        <th>لندینگ</th>
                                        <th>زمان</th>
                                        <th>عملیات</th>

                                    </tr>

                                </thead>


                                <tbody>


                                    {allStatistics.map((item, index) => {


                                        //cal date and time create
                                        const dateAndTimeCreate = item.created_at;
                                        const splitDataAndTimeCreate = dateAndTimeCreate.split(" ");
                                        const date = convertToPersianDate(splitDataAndTimeCreate[0]);
                                        const time = splitDataAndTimeCreate[1];



                                        let iconBrowser = "";
                                        let iconOs = "";

                                        //for icon Browser
                                        switch (item.user_browser) {

                                            case "Internet_Explorer": iconBrowser = "1687073676.png";
                                                break;

                                            case "Mozilla_Firefox": iconBrowser = "1687025587.png";
                                                break;

                                            case "Google_Chrome": iconBrowser = "1687088292.png";
                                                break;

                                            case "Apple_Safari": iconBrowser = "1687242664.png";
                                                break;

                                            case "Opera": iconBrowser = "1687070280.png";
                                                break;

                                            case "Netscape": iconBrowser = "1687090785.png";
                                                break;

                                            case "Unknown": iconBrowser = "1687005752.png";
                                                break;

                                            default: iconBrowser = item.user_browser;
                                                break;

                                        }

                                        //for icon platform
                                        if (item.user_platform === "iPhone" || item.user_platform === "ipad" || item.user_platform === "mac") iconOs = "1687048931.png";
                                        else if (item.user_platform === "Windows_Phone" || item.user_platform === "windows") iconOs = "1687040029.png"
                                        else if (item.user_platform === "Android") iconOs = "1687016938.png";
                                        else if (item.user_platform === "linux") iconOs = "1687047036.png";
                                        else if (item.user_platform === "Unknown") iconOs = "1687005752.png";




                                        return <tr key={index}>

                                            <td>{metaPages.from + index}</td>
                                            <td>{item.user_ip}</td>
                                            <td><img src={`https://cdn.hamkadeh.com/up-files/${iconBrowser}`} alt={`iconBrower${iconBrowser}`} className={styles.iconsShow} /></td>
                                            <td><img src={`https://cdn.hamkadeh.com/up-files/${iconOs}`} alt={`iconOs${iconOs}`} className={styles.iconsShow} /></td>
                                            <td>{item.landing?.title ? <Link to={`/dashboard/showLandings/${item.landing.id}`}>{item.landing?.title}</Link> : "حذف شده"}</td>
                                            <td>{`${date} - ${time}`}</td>

                                            <td>

                                                <Link to={`/dashboard/visits/${item.id}`} style={{ color: "#fff" }}><button className="btn btn-dark btn-sm mx-1">جزئیات</button></Link>


                                            </td>

                                        </tr>
                                    })}



                                </tbody>


                            </table>



                        </div>


                        {length !== 3 && <MyPagination meta={metaPages} myPath={myPath} paginationHandler={paginationHandler} joinqueries={joinqueries}/>}

                    </div>


                </>

                :

                <ShowNothing text="آماری وجود ندارد" />

            :

            <Loading color="#7545fb" />
    );
};

export default ShowStatistics;