import React, { useContext, useEffect, useState } from 'react';

//styles
import styles from "./Dashboard.module.scss";

//contexts
import { myUserDataContext } from '../App';

//dependencys
import { useNavigate } from 'react-router-dom';
import { Link, Outlet } from 'react-router-dom';
import axios from 'axios';


//functions
import { responseHandler } from '../functions/responseHandler';

import ModalYesorNo from '../shared/ModalYesorNo';
import { notify } from '../functions/toasts';



const Dashboard = () => {



    const data = useContext(myUserDataContext);
    const { userData, isLogin, setIsLogin, UpdateLocalStorage, setUserData  , keySearch ,setKeySearch } = data;
    const { token, user } = userData;


    //for search in hole of dashboard    
    const [searcQuery , setSearcQuery] = useState("");


    const [showDropProfile, setShowDropProfile] = useState(false)
    const [toogleMenu, setToggleMenu] = useState(true);


    const navigate = useNavigate();



    //for logout when any changes in localStorage or get code 401
    useEffect(() => {

        const handleStorageChange = () => {

           exitHandler();
           
        };

        window.addEventListener('storage', handleStorageChange);


        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };

    }, [])



    

    //for update localStorage data in any components with updateLocalSrtoage state dependency
    useEffect(() => {

        axios.get("/profile/current-user", {

            headers: {

                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => {

                if (res.data.status) {

                    setUserData({ ...userData, user: { ...userData.user, ...res.data.data } }, (nowUserData) => {

                        window.localStorage.setItem("userData", JSON.stringify(nowUserData))

                    });

                }
            })
            .catch(error => {

                responseHandler(error, "error");
            })



    }, [UpdateLocalStorage])




    //for check login in past or not
    useEffect(() => {

        if (!isLogin) navigate("/");

    }, [isLogin])




    //for click logout btn
    const exitHandler = () => {

        axios
            .post("/auth/logout", {}, {

                headers: {

                    'Authorization': `Bearer ${token}`
                }
            })
            .then(res => {

                responseHandler(res, "succ");
                if (res.data.status) {

                    setIsLogin(false);
                    localStorage.clear();

                }
            })
            .catch(error => {

                responseHandler(error, "error");
            })
    }


    //for active ul menu in dashboard
    const menuItems = ["dashboard", "users", "installTemplate", "showTemplates", "showLandings", "visits" , "roles"];
    const [activeItem, setActiveItem] = useState("");

    useEffect(() => {

        const SplitUrl = window.location.href.split("/");

        for (let i = 0; i < menuItems.length; i++) {

            if (SplitUrl[4] === menuItems[i]) {

                setActiveItem(menuItems[i]);
                return;
            }
        }

        if (SplitUrl[4] !== undefined) {

            for (let i = 0; i < menuItems.length; i++) {

                const mySplit = SplitUrl[4].split("?");
                if (mySplit[0] === menuItems[i]) {

                    setActiveItem(menuItems[i]);
                    return;
                }
            }
        }

        for (let i = 0; i < menuItems.length; i++) {

            if (SplitUrl[3] === menuItems[i]) {

                setActiveItem(menuItems[i]);
                return;
            }
        }

    }, [window.location.href])




    //for onSearch click or enter
    const searcHandler = (e) =>{

        e.preventDefault();

        // if(searcQuery === ""){
            
        //     notify("چیزی برای جستجو وارد نکردید" , "error");
        //     return;
        // }
        setKeySearch(!keySearch)
        navigate(`/dashboard/showLandings?title=${searcQuery}`)
    }




    return (

        isLogin &&

        <>
            <div className={styles.myDash}>



                <div className={styles.menuDash} style={{ width: toogleMenu ? "380px" : "0px", transform: toogleMenu ? "translateX(0px)" : "translateX(380px)" }}>

                    <div>

                        <div className={styles.logoDiv}>

                            <img src="https://cdn.hamkadeh.com/up-files/1685738734.png" alt="logoLandingMaker" className={styles.logoLandingMaker} />
                            <span className={styles.textLogo}>LANDING MAKER</span>


                        </div>


                        <div className={styles.menuUl}>

                            <ul>

                                <li><Link to="/dashboard" className={activeItem === "dashboard" ? styles.active : ''}><i className="bi bi-grid"></i><span>داشبورد</span></Link></li>
                                <li><Link to="/dashboard/users" className={activeItem === "users" ? styles.active : ''}><i className="bi bi-people"></i><span>کاربران</span></Link></li>
                                <li><Link to="/dashboard/installTemplate" className={activeItem === "installTemplate" ? styles.active : ''} ><i className="bi bi-box-arrow-in-down"></i><span>نصب قالب</span></Link></li>
                                <li><Link to="/dashboard/showTemplates" className={activeItem === "showTemplates" ? styles.active : ''}><i className="bi bi-layers"></i><span>نمایش قالب ها</span></Link></li>
                                <li><Link to="/dashboard/showLandings" className={activeItem === "showLandings" ? styles.active : ''}><i className="bi bi-file-earmark-richtext"></i><span>نمایش لندینگ ها</span></Link></li>
                                <li><Link to="/dashboard/roles" className={activeItem === "roles" ? styles.active : ''}><i className="bi bi-shield-shaded"></i><span>نقش ها</span></Link></li>
                                <li><Link to="/dashboard/visits" className={activeItem === "visits" ? styles.active : ''}><i className="bi bi-bar-chart-line"></i><span>آمار</span></Link></li>


                            </ul>

                        </div>

                    </div>

                    <div>

                        <div className={styles.menuUl}>

                            <ul>

                                <li><Link to="/" data-bs-toggle="modal" data-bs-target="#exitModal"><i className="bi bi-x-octagon"></i><span>خروج</span></Link></li>


                            </ul>

                        </div>

                    </div>

                </div>




                <div className={styles.contentDash} style={{ width: "100%" }} >




                    <div className={styles.HeaderDash}>



                        <div className={styles.rtightHeader}>


                            <i className={`bi bi-list ${styles.headerIcons}`} id={styles.hamburgerMenu} onClick={() => setToggleMenu(!toogleMenu)}></i>
                            <Link to="/dashboard"><i className={`bi bi-house ${styles.headerIcons}`}  ></i></Link>

                            <form className={styles.searchDiv} onSubmit={searcHandler}>

                                <input type="text" placeholder='جستجو ...' value={searcQuery} onChange={(e) => setSearcQuery(e.target.value)}/>
                                <button className={styles.btnSearch}><i className="bi bi-search" ></i></button>

                            </form>




                        </div>



                        <div className={styles.leftHeader}>


                            <i className={`bi bi-chat-square-dots-fill ${styles.headerIcons}`}></i>
                            <i className={`bi bi-app-indicator ${styles.headerIcons}`}></i>

                            <div className={styles.divPicPro} onClick={() => setShowDropProfile(!showDropProfile)} onMouseLeave={() => setShowDropProfile(false)}>

                                <i className="bi bi-caret-down-fill" id={styles.arrowDown}></i>
                                <div className={styles.userInfo}>
                                    <span>{`${userData.user.first_name} ${userData.user.last_name}`}</span>
                                </div>

                                <img src={user.gender === "male" ? 'https://cdn.hamkadeh.com/up-files/1685744538.png' : 'https://cdn.hamkadeh.com/up-files/1686400650.png'} alt='PicProgile' className={styles.picProfile} />

                                <div className={styles.dropDownProfile} style={{ display: showDropProfile ? "block" : "none" }}>

                                    <ul className={styles.ulDropDown}>

                                        <li><Link to={`/dashboard/profile`}><i className="bi bi-person-fill"></i>پروفایل</Link></li>
                                        <li><Link to="#" data-bs-toggle="modal" data-bs-target="#exitModal"><i className="bi bi-x-octagon"></i>خروج</Link></li>

                                    </ul>


                                </div>


                            </div>




                        </div>


                    </div>


                    <div className={styles.mainContent}>



                        <Outlet />




                    </div>

                </div>



            </div>

            <ModalYesorNo idModal="exitModal" doBtn="خروج" text="آیا می‌خواهید خارج شوید؟" Click={exitHandler} />

        </>

    );
};

export default Dashboard;