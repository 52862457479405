import React, { useEffect, useRef, useState } from 'react';
import axios from "axios";

//styles
import styles from "./LoginPage.module.scss";

//functions
import { changeFaToEn } from "../functions/changeFaToEn";
import { notify } from "../functions/toasts";
import { responseHandler } from '../functions/responseHandler';
import { trueMobileOrNot, sendRequestForForgotPassCode } from '../functions/ApiForLogin';


import {useNavigate } from 'react-router-dom';



const LoginPage = ({ setUserData, setIsLogin, isLogin }) => {


    const [typeShow, setTypeShow] = useState("getMobile");
    const [mobile, setMobile] = useState("");


    const [receivedCode, setReceivedCode] = useState("");
    const [passWord, setPassword] = useState("");
    const [receivedCodeForgotPass, setReceivedCodeForgotPass] = useState("");
    const [newPass, setNewPass] = useState("");

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    //for check login in past or not
    useEffect(() => {

        if (isLogin) navigate("/dashboard");

    }, [isLogin])



    //for focus iputs
    const InputRef = useRef(null);
    useEffect(() => {

        if (!isLogin) InputRef.current.focus();

    }, [typeShow])


    //for back Handler
    const backHandler = () => {

        typeShow === "forgotPass" ? setTypeShow("withPass") : setTypeShow("getMobile");

    }



    //for handler of Inputs is Login Page
    const regex = /^(\+98|0098|98|0)?9\d{9}$/;
    const InputHandler = (e, type) => {

        const first = changeFaToEn(e.target.value);

        if (type === "isMobile") setMobile(first);
        else if (type === "isResevedCode") setReceivedCode(first);
        else if (type === "isPass") setPassword(first);
        else if (type === "isReceicedCodeForgotPass") setReceivedCodeForgotPass(first);
        else if (type === "isNewPass") setNewPass(first);


    }



    //for step 1 for know mobile is in data base or not
    const trueNumberHandler = (e) => {

        const checkMobile = regex.test(mobile);

        if (mobile === "") {

            notify("شماره موبایل وارد نشده است", "error");
            return;
        }

        if (!checkMobile) {

            notify("شماره موبایل صحیح وارد نشده", "error");
            return;
        }

        setLoading(true);


        trueMobileOrNot(e, setTypeShow, mobile, setLoading);
    }



    //for when click on forgot password text
    const ForgotPassHandler = () => {

        setLoading(true);
        setTypeShow("forgotPass");
        setReceivedCodeForgotPass("");
        setNewPass("");
        sendRequestForForgotPassCode(mobile, setLoading);
    }


    //for change pass
    const changePassHandler = () => {


        if (receivedCodeForgotPass === "") {

            notify("کد ارسال شده به شماره موبایل وارد نشده است", "error");
            return;

        }

        if (newPass === "") {

            notify("رمز عبور جدید وارد نشده است", "error");
            return;
        }

        setLoading(true);

        axios
            .post("/auth/forgot-password", { mobile: mobile, code: receivedCodeForgotPass, password: newPass })
            .then(res => {

                responseHandler(res, "succ");
                setReceivedCodeForgotPass("");
                setNewPass("");
                setTypeShow("withPass");
                setLoading(false);

            })
            .catch(error => {

                responseHandler(error, "error");
                setLoading(false);
            })

    }




    //when login with code
    const loginWithReceivedCode = (e) => {

        e.preventDefault();

        if (receivedCode === "") {

            notify("کدی وارد نکردید!", "error");
            return;
        }

        setLoading(true);

        axios
            .post("/auth/login-with-mobile", { mobile: mobile, code: receivedCode })
            .then(res => {

                responseHandler(res, "succ");
                if (res.data.status) {

                    setUserData(res.data.data , (nowUserData) =>{

                        window.localStorage.setItem("userData" , JSON.stringify(nowUserData))
                    });
                    setIsLogin(true, (nowIsLogin) => {

                        window.localStorage.setItem("isLogin", JSON.stringify(nowIsLogin))
                    });

                    setLoading(false);

                    navigate("/dashboard");
                }

            })
            .catch(error => {

                responseHandler(error, "error");
                setLoading(false);
            })
    }



    //when login with pass
    const loginWithPassword = (e) => {

        e.preventDefault();

        if (passWord === "") {

            notify("رمز عبوری وارد نکردید!", "error");
            return;
        }

        setLoading(true);

        axios
            .post("/auth/login", { mobile: mobile, password: passWord })
            .then(res => {

                responseHandler(res, "succ");
                if (res.data.status) {

                    setUserData(res.data.data , (nowUserData) =>{

                        window.localStorage.setItem("userData" , JSON.stringify(nowUserData))
                    });
                    setIsLogin(true, (nowIsLogin) => {

                        window.localStorage.setItem("isLogin", JSON.stringify(nowIsLogin))
                    });


                    setLoading(false);

                    navigate("/dashboard");
                }
            })
            .catch(error => {
                console.log(error)

                responseHandler(error, "error");
                setLoading(false);
            })
    }




    return (

        !isLogin &&
        <>

            <div className={styles.mainCon}>
            
                <img src='https://cdn.hamkadeh.com/up-files/1685738734.png' alt='loginIcon' className={styles.loginIcon} />


                {typeShow !== "getMobile" && <div className={styles.back}>
                    <span onClick={backHandler}>بازگشت</span>
                </div>}



                <div className={styles.loginCon} >


                    {typeShow === "getMobile" && <div>

                        <div>
                            <div className={styles.InputDiv}>
                                <label htmlFor='mobileInput'>شماره موبایل:</label>
                                <input type="text" id="mobileInput" placeholder='09---------' ref={InputRef} value={mobile} onChange={(e) => InputHandler(e, "isMobile")} />
                            </div>
                        </div>

                        <div className={styles.buttons}>

                            <button id="btnWithPass" onClick={trueNumberHandler}>ورود با رمز عبور</button>
                            <button id="btnWithsms" onClick={trueNumberHandler}>ورود با کد پیامکی</button>

                        </div>

                    </div>}


                    {typeShow === "withPass" && <form onSubmit={loginWithPassword} >

                        <div>
                            <div className={styles.showNumber}>

                                <span>{`برای شماره: ${mobile}`}</span>

                            </div>
                            <div className={styles.InputDiv}>
                                <label htmlFor='passInput'>رمز عبور:</label>
                                <input type="password" id="passInput" ref={InputRef} value={passWord} onChange={(e) => InputHandler(e, "isPass")} />
                            </div>
                        </div>

                        <div className={styles.buttons}>

                            <button type="submit" style={{ width: "100%", margin: "0" }}>ورود</button>

                        </div>

                    </form>}


                    {typeShow === "withCode" && <form onSubmit={loginWithReceivedCode}>

                        <div>
                            <div className={styles.showNumber}>

                                <span>{`برای شماره: ${mobile}`}</span>

                            </div>
                            <div className={styles.InputDiv}>
                                <label htmlFor='codeInput1'>کد ارسال شده:</label>
                                <input type="text" id="codeInput1" placeholder='- - - -' ref={InputRef} value={receivedCode} onChange={(e) => InputHandler(e, "isResevedCode")} />
                            </div>
                        </div>

                        <div className={styles.buttons}>


                            <button type="submit" style={{ width: "100%", margin: "0" }}>ورود</button>


                        </div>

                    </form>}



                    {typeShow === "forgotPass" && <div>

                        <div>

                            <div className={styles.showNumber}>

                                <span>{`برای شماره: ${mobile}`}</span>

                            </div>

                            <div className={styles.InputDiv} style={{ marginBottom: "1rem" }}>
                                <label htmlFor='codeInput2'>کد ارسال شده:</label>
                                <input type="text" id="codeInput2" placeholder='- - - -' ref={InputRef} value={receivedCodeForgotPass} onChange={(e) => InputHandler(e, "isReceicedCodeForgotPass")} />
                            </div>

                            <div className={styles.InputDiv}>
                                <label htmlFor='newPass'>رمز عبور جدید:</label>
                                <input type="password" id="newPass" value={newPass} onChange={(e) => InputHandler(e, "isNewPass")} />
                            </div>

                        </div>

                        <div className={styles.buttons}>

                            <button style={{ width: "100%", margin: "0" }} onClick={changePassHandler}>ثبت</button>

                        </div>

                    </div>}


                    {loading && <div className={styles.loading}>

                        <div className="spinner-border" role="status" style={{color : "#7545fb"}}>
                            <span className="visually-hidden">Loading...</span>
                        </div>

                    </div>}



                </div>

                {typeShow === "withPass" ? <span className={styles.forgetPass} onClick={ForgotPassHandler}>فراموشی رمز عبور</span> : <span style={{ margin: "1rem 0", color: "#c0c0c0", fontSize: "0.7rem", userSelect: "none" }}>LandingMaker@</span>}

            </div >



        </>
    );
};

export default LoginPage;