import React, { useContext, useEffect } from 'react';
import styles from "./Page404.module.scss";

import { myUserDataContext } from '../../App';
import { useNavigate } from 'react-router-dom';



const Page404 = () => {

    const data = useContext(myUserDataContext);
    const { isLogin } = data;

    const navigate = useNavigate();

    useEffect(() => {


        if (!isLogin) navigate("/");

    }, [isLogin])

    return (

        isLogin && 
        <>
            

        <div className={styles.notfoundDiv}>

            <div className={styles.not}>

                <img src="https://s8.uupload.ir/files/404_cngu.gif" alt="404NotFound" />
                <span>صفحه مورد نظر وجود ندارد</span>
                <button className='btn btn-primary mt-3' onClick={() => navigate("/")}>صفحه اصلی</button>

            </div>

        </div>




        </>
    );
};

export default Page404;