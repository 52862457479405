
import { notify } from "../functions/toasts";
import axios from "axios";



export const responseHandler = (res, type, showmessage = true) => {



    if (type === "succ") {


        if (showmessage) notify(res.data.message, "success");
    }
    else {


        //for exit
        if (res.response?.status === 401) {


            let token = (JSON.parse(localStorage.getItem("userData")));
            token = token?.token;

            axios
                .post("/auth/logout", {}, {

                    headers: {

                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(res => {

                    if (res.data.status) {

                        localStorage.clear();
                        window.location.reload();
                        notify("لطفا دوباره وارد شوید", "error");
                    }
                })
                .catch(error => {

                    console.log(error)
                    notify("مشکلی پیش آمده است", "error");
                    localStorage.clear();
                    window.location.reload();
                })

        }

        if (res.response?.data.status === false) {

            console.log(res)
            if (showmessage) {


                if (typeof res.response.data.message === "object") {

                    const keysMessage = Object.keys(res.response.data.message);

                    notify(res.response.data.message[keysMessage[0]][0], "error", 4000);

                }
                else {

                    notify(res.response.data.message, "error");

                }


            }
            return;
        }

        console.log(res)
        if (showmessage) notify("مشکلی پیش آمده", "error");

    }

}