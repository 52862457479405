import React, { useEffect, useContext ,useState } from 'react';

//styles 
import styles from "./DetailTemplate.module.scss";

//dependency
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';


//context
import { myUserDataContext } from '../../App';


//component
import Loading from '../../shared/Loading';

//functions
import { convertToPersianDate } from '../../functions/convertToPersianDate';
import { responseHandler } from '../../functions/responseHandler';




const DetailTemplate = () => {

    const params = useParams();
    const navigate = useNavigate();

    const data = useContext(myUserDataContext);
    const { userData, isLogin } = data;
    const { token } = userData;

    const [detailTemplate, setDetailTemplate] = useState({});
    const [isLoad, setIsLoad] = useState(true);




    useEffect(() => {

        axios.get(`/template/show/${params.id}`, {

            headers: {

                'Authorization': `Bearer ${token}`,
            }
        })
            .then(res => {
                
                responseHandler(res, "succ", false);
                if (res.data.status) {

                    setDetailTemplate(res.data.data);
                    setIsLoad(false);
                }

            })
            .catch(error => {

                responseHandler(error, "error");
                if (error.response?.status === 404) navigate("/notfound", { replace: true });
            })


    }, [])


    const { screenshot_url, title, slug, created_at, updated_at, status, description, landings_count} = detailTemplate;

    

    if (!isLoad) {

        var installDate = created_at.split(" ");
        var updateDate = updated_at.split(" ");

    }

    

    return (

        !isLoad && isLogin ?

            <>

                <div className={styles.DetailTemplateDiv}>


                    <div className={styles.Top}>

                        <div className={styles.rightTop}>

                            <div className={styles.nameSlug}>

                                <span className={styles.titleTemp}>{`نام قالب: ${title}`}</span>
                                <span className={styles.slugTemp}>{`آدرس قالب: ${slug}`}</span>

                            </div>

                            <div className={styles.CounterInstall}>

                                <span>تعداد لندینگ</span>
                                <span>{landings_count}</span>

                            </div>


                        </div>

                        <div className={styles.leftTop}>

                            <div className={styles.datesDiv}>

                                <div className={styles.dates}>

                                    <i className="bi bi-calendar-plus-fill"></i>
                                    <span>{`نصب شده در: ${convertToPersianDate(installDate)}`}</span>
                                    <span>{`آپدیت شده در: ${convertToPersianDate(updateDate)}`}</span>


                                </div>

                                <div className={styles.status}>


                                    <i className={`bi bi-shield-fill-${status === "active" ? 'check' : 'x'}`} style={{ backgroundColor: status === "active" ? '#03ac13' : '#e3242b' }}></i>
                                    <span>{status === "active" ? 'فعال' : 'غیرفعال'}</span>

                                </div>


                            </div>


                            <img src={screenshot_url} alt="imgTemplate" className={styles.ImgTemplate} />

                        </div>

                    </div>


                    <div className={styles.center}>

                        <div className={styles.decripDiv}>

                            <span>راهنمای قالب</span>

                            <p>
                                {description === null ? 'این قالب راهنمایی ندارد' : description}
                            </p>
                        </div>

                        <div className="d-grid gap-2">
                        <button className={`btn btn-primary ${styles.backBtn}`} onClick={() => navigate(-1)}> بازگشت به قالب ها</button>
                        </div>

                    </div>

                </div>







            </>
            :
            <Loading color="#7545fb" />

    );
};

export default DetailTemplate;