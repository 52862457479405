import React, { useContext, useEffect, useState } from 'react';

//styles
import styles from "./DetailLanding.module.scss";

//contexts
import { myUserDataContext } from '../../App';

//dependency
import { useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';

//component
import Loading from '../../shared/Loading';

//functions
import { convertToPersianDate } from '../../functions/convertToPersianDate';
import { responseHandler } from '../../functions/responseHandler';
import { notify } from '../../functions/toasts';


const DetailLanding = () => {

    const params = useParams();
    const navigate = useNavigate();

    const [checkBtnDownload, setCheckBtnDownload] = useState(false);

    const data = useContext(myUserDataContext);
    const { userData, isLogin, mainUrl } = data;
    const { token } = userData;

    const [detailLanding, setDetailLanding] = useState({});
    const [onlineUser, setOnlineUser] = useState("");
    const [isLoad, setIsLoad] = useState(true);


    const getOnline = async (id) => {
        try {

            const res = await axios.get(`/statistic/online-client-number/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (res.data.status) {
                setOnlineUser(res.data.data);
            }

        } catch (error) {

            responseHandler(error, "error");
        }
    };

    useEffect(() => {

        const fetchData = async () => {

            setIsLoad(true);

            try {
                const res = await axios.get(`/landing/show/${params.id}?relations[]=template&relations[]=user`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });

                responseHandler(res, "succ", false);
                if (res.data.status) {
                    setDetailLanding(res.data.data);
                    await getOnline(res.data.data.id);
                }
            } catch (error) {

                responseHandler(error, "error");
                if (error.response?.status === 404) navigate("/notfound", { replace: true });

            } finally {

                setIsLoad(false);
            }
        };

        fetchData();
    }, []);




    //for download excel handler
    const downloadHandler = () => {

        setCheckBtnDownload(true);
        axios({
            url: `/landing-extra/excel-download/${params.id}`,
            method: 'GET',
            headers: {

                'Authorization': `Bearer ${token}`
            },
            responseType: 'blob',
        })
            .then(res => {

                if (res && res.data instanceof Blob) {

                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${detailLanding.title}.xlsx`); // نام دلخواه برای فایل با پسوند Excel


                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    setCheckBtnDownload(false);

                } else {

                    console.log(res);
                    notify("خطا در دریافت فایل اکسل", "error");
                    setCheckBtnDownload(false);
                }
            })
            .catch(error => {

                console.log(error);
                notify("خطا در دریافت فایل اکسل", "error");
                setCheckBtnDownload(false);
            })
    }



    const { id, title, slug, user, template, statistics_count, created_at, updated_at } = detailLanding;

    if (!isLoad) {

        //cal date and time create
        const dateAndTimeCreate = created_at;
        const splitDataAndTimeCreate = dateAndTimeCreate.split(" ");
        var dateCreate = convertToPersianDate(splitDataAndTimeCreate[0]);
        var timeCreate = splitDataAndTimeCreate[1];

        //cal date and time Update
        const dateAndTimeUpdate = updated_at;
        const splitDataAndTimeUpdate = dateAndTimeUpdate.split(" ");
        var dateUpdate = convertToPersianDate(splitDataAndTimeUpdate[0]);
        var timeUpdate = splitDataAndTimeUpdate[1];
    }

    return (

        !isLoad && isLogin ?

            <>

                <div className={styles.DetailLandingDiv}>


                    <div className={styles.infoTop}>

                        <div className={styles.iconDiv}><i className="bi bi-file-earmark"></i></div>

                        <div className={styles.textsInfo}>

                            <span className={styles.title}>{title}</span>
                            <span className={styles.slug}>{`آدرس: `}<Link to={`${mainUrl}${slug}`} target='_blank'>{slug}</Link></span>

                        </div>

                    </div>

                    <div className={styles.iconDown}><i className="bi bi-caret-down-fill"></i></div>


                    <div className={styles.infoCenter}>

                        <div className={`${styles.cardLanding} ${styles.card1}`}>

                            <Link to={`/dashboard/visits?landing_id=${params.id}`}><span>{statistics_count}</span></Link>
                            <span>بازدید</span>

                        </div>


                        <div className={`${styles.cardLanding} ${styles.card2}`}>



                            <div className={styles.nameTitle}>

                                <span>سازنده: {`${user?.first_name ?? "حذف شده"} ${user?.last_name ?? "حذف شده"}`}</span>
                                <span>قالب: {`${template?.title ?? "حذف شده"}`}</span>

                            </div>



                            <div className={styles.createUpdate}>

                                <span>ایجاد: {`${dateCreate} ${timeCreate}`}</span>
                                <span>آپدیت: {`${dateUpdate} ${timeUpdate}`}</span>

                            </div>



                        </div>



                        <div className={`${styles.cardLanding} ${styles.card3}`}>

                            <div className={styles.onlineDiv}>

                                <span className={styles.glow} >{onlineUser}</span>

                            </div>

                            <span>آنلاین</span>

                        </div>


                    </div>


                    <div className={styles.btns}>

                        <button className='btn btn-primary' onClick={() => navigate(-1)}>{`بازگشت`}</button>
                        <button className='btn btn-success me-2' style={{ color: "#fff" }} onClick={downloadHandler} disabled={checkBtnDownload}>
                            {`دانلود اکسل`}{checkBtnDownload ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> : <i className="bi bi-file-spreadsheet-fill me-2"></i>}
                        </button>



                    </div>




                </div>


            </>

            :

            <Loading color="#7545fb" />
    );
};

export default DetailLanding;