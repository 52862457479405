import { toast } from 'react-toastify';

 export const notify = (text , type , time = 1500) => {

    if (type === "success") {

        toast.success(text , {

            pauseOnHover: false,
            theme: "colored",
            autoClose: time,

            });
    }
    else if (type === "error") {

        toast.error(text , {

            pauseOnHover: false,
            theme: "colored",
            autoClose: time,

            });
    }
}
