import React from 'react';

//styles
import styles from "./ContentDashboard.module.scss";

const ContentDashboard = ({title , content}) => {

    return (

        <>

            <div className={styles.contentContainer}>

                <div className={styles.titlePage}>

                    <span>{title}</span>
                </div>

                <div className={styles.ContentBody}>

                    {content}

                </div>

            </div>




        </>
    );
};

export default ContentDashboard;