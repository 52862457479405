import React, { useContext, useEffect, useState } from 'react';

//styles
import styles from "./DetailStatistics.module.scss";

//dependencys
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

//contexts
import { myUserDataContext } from '../../App';

//shared
import Loading from '../../shared/Loading';

//functions
import { responseHandler } from '../../functions/responseHandler';
import { convertToPersianDate } from '../../functions/convertToPersianDate';


const DetailStatistics = () => {

    const params = useParams();
    const navigate = useNavigate();

    const data = useContext(myUserDataContext);
    const { userData, isLogin } = data;
    const { token } = userData;

    const [detaiStatistics, setDetaiStatistics] = useState({});
    const [isLoad, setIsLoad] = useState(true);


    useEffect(() => {

        axios
            .get(`/statistic/show/${params.id}?relations[]=landing`, {

                headers: {

                    'Authorization': `Bearer ${token}`,
                }
            })
            .then(res => {

                if (res.data.status) {

                    setDetaiStatistics(res.data.data);
                    setIsLoad(false);
                }

            })
            .catch(error => {

                responseHandler(error, "error");
            })


    }, [])


    const { user_ip, user_browser, user_platform, landing, previous_url, query_string, created_at } = detaiStatistics;

    if (!isLoad) {

       //cal date and time create
       const dateAndTimeCreate = created_at;
       const splitDataAndTimeCreate = dateAndTimeCreate.split(" ");
       var date = convertToPersianDate(splitDataAndTimeCreate[0]);
       var time = splitDataAndTimeCreate[1];
    }

    return (

        !isLoad && isLogin ?

            <>

                <div className={styles.DetailStatisticsDiv}>


                    <div className={styles.content}>

                        <span>{`IP: ${user_ip}`}</span>
                        <span>{`مرورگر: ${user_browser}`}</span>
                        <span>{`سیستم عامل: ${user_platform}`}</span>
                        <span>{`لندینگ: ${landing === null ? "حذف شده" : landing.title}`}</span>
                        <span>{`آدرس قبلی: `}<a href={previous_url} target='_blank'>{previous_url}</a></span>
                        <span>{`پارامتر ها: ${query_string == "[]" ? 'ندارد' : query_string}`}</span>
                        <span>{`زمان: ${date}  ${time}`}</span>



                    </div>

                </div>

            </>

            :

            <Loading color="#7545fb" />

    );
};

export default DetailStatistics;