import moment from 'jalali-moment';


export const convertToPersianDate = (miladiDate) => {
    const miladiFormat = 'YYYY-MM-DD';
    const shamsiFormat = 'jDD-MMM-jYYYY';
  
    const shamsiDate = moment(miladiDate, miladiFormat).locale('fa').format(shamsiFormat);
  
    return shamsiDate;
};



