import axios from "axios"
import { responseHandler } from "./responseHandler";



const sendRequestForCode = async (mobile , setLoading) => {

     await axios   
        .post("/auth/send-code", {mobile : mobile})
        .then(res => {

            responseHandler(res , "succ");
            setLoading(false);
        })
        .catch(error =>{

            responseHandler(error, "error");
            setLoading(false);
        })


}



export const trueMobileOrNot = async (e, setTypeShow, mobile , setLoading) => {

    await axios
        .post(`/auth/find-by-mobile/${mobile}`)
        .then(res => {


            responseHandler(res, "succ", false);
            if (res.data.status) {

                if (e.target.id === "btnWithPass") {

                    setTypeShow("withPass");
                    setLoading(false);

                }
                else {

                    sendRequestForCode(mobile , setLoading)
                    setTypeShow("withCode");
                    
                }

            }

        })
        .catch(error => {

            responseHandler(error, "error");
            setLoading(false);

        })

}



export const sendRequestForForgotPassCode = (mobile , setLoading) => {

     axios   
       .post("/auth/forgot-password-code", {mobile : mobile})
       .then(res => {

           responseHandler(res , "succ");
           setLoading(false);
       })
       .catch(error =>{

           responseHandler(error, "error");
           setLoading(false);
       })


}