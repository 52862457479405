import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';

//styles
import styles from "./ShowTemplates.module.scss";

//contexts
import { myUserDataContext } from '../../App';

//functions
import { responseHandler } from '../../functions/responseHandler';

//dependencys
import { useLocation, Link, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

//components
import Loading from '../../shared/Loading';
import ModalYesorNo from '../../shared/ModalYesorNo';
import ShowNothing from '../../shared/ShowNothing';

//shared
import MyPagination from "../../shared/MyPagination";

const ShowTemplates = () => {

    const navigate = useNavigate();
    const locations = useLocation().search;
    const myQueryString = queryString.parse(locations);

    const data = useContext(myUserDataContext);
    const { userData , isLogin} = data;
    const { token } = userData;

    const [AllTempaltes, setAllTempaltes] = useState([]);
    const [isLoad , setIsLoad] = useState(true);
    const [metaPages, setMetaPage] = useState({});
    const [UpdateListTemplates, setUpdateListTemplates] = useState(false);


    //for make queries
    let joinqueries = [];
    for(let item in myQueryString){

        if(item === "page") continue ;
        const temp = `${item}=${myQueryString[item]}`;
        joinqueries.push(temp);
        
    }
    joinqueries = joinqueries.join("&");


    //get all Templates
    useEffect(() => {
        
        setIsLoad(true)
        axios.get(`/template/index?page=${myQueryString.page}${joinqueries !== "" ? `&${joinqueries}` : ""}`, {

            headers: {

                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => {

                if (res.data.status) {


                    setAllTempaltes(res.data.data);
                    setIsLoad(false);
                    setMetaPage(res.data.meta)
                }
            })
            .catch(error => {

                responseHandler(error, "error");
            })

    }, [UpdateListTemplates]);



    //for delete Template
    const deleteTemplateHandler = (id) => {

       
        axios
            .delete(`/template/delete/${id}`, {

                headers: {

                    'Authorization': `Bearer ${token}`
                }
            })
            .then(res => {

                responseHandler(res, "succ");
                if (res.data.status) {

                    
                    setUpdateListTemplates(!UpdateListTemplates);
                    navigate(`/dashboard/showTemplates`)
                }
            })
            .catch(error => {

                responseHandler(error, "error");
            })


    }



    //for click on any of paginations
    const paginationHandler = (url) => {

        setIsLoad(true);
        axios.get(`/template/index?page=${url}${joinqueries !== "" ? `&${joinqueries}` : ""}`, {

            headers: {

                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => {

                if (res.data.status) {


                    setAllTempaltes(res.data.data);
                    setIsLoad(false);
                    setMetaPage(res.data.meta)
                }
            })
            .catch(error => {

                responseHandler(error, "error");
            })

    }





    if (AllTempaltes.length) {

        var length = metaPages.links.length;
    }


    const myPath = "/dashboard/showTemplates";



    return (

        !isLoad  && isLogin ?

            AllTempaltes.length ? 

            
            <>

                <div className={styles.DivShowTemplate}>

                    <div className={styles.TopOfTable}>

                        <span className={styles.InfoCountTable}>{`نمایش ${metaPages.from} تا ${metaPages.to} از ${metaPages.total} مورد`}</span>
                        <Link to="/dashboard/showTemplates/trash-list"><button className='btn btn-danger btn-sm'><i className="bi bi-trash3-fill ms-1"></i><span>سطل زباله</span></button></Link>

                    </div>



                    <div className="table-responsive mx-2"  >
                        <table className="table table-bordered table-striped" >

                            <thead>

                                <tr>

                                    <th>ردیف</th>
                                    <th>نام قالب</th>
                                    <th>آدرس</th>
                                    <th>تعداد لندینگ</th>
                                    <th style={{ textAlign: "center" }}>پیش نمایش</th>
                                    <th>وضعیت</th>
                                    <th>عملیات</th>

                                </tr>

                            </thead>


                            <tbody>

                            
                                {AllTempaltes.map((item, index) => {

                                    return <tr key={index}>

                                        <td>{metaPages.from + index}</td>
                                        <td>{item.title}</td>
                                        <td>{item.slug}</td>
                                        
                                        <td className='user-select-none'>{item.landings_count == 0 ? `${item.landings_count} لندینگ` : <Link to={`/dashboard/showLandings?template_id=${item.id}`}>{item.landings_count} لندینگ</Link>}</td>
                                        <td style={{ textAlign: "center" }}>{item.screenshot_url !== null && <img src={item.screenshot_url} alt={`picTemplate${item.id}`} className={styles.screenimage} />}</td>

                                        <td >{item.status === "active" ? <i className="bi bi-shield-fill-check" style={{ fontSize: "1.3rem", color: "#03ac13" }}></i> : <i className="bi bi-shield-fill-x" style={{ fontSize: "1.3rem", color: "#d0312d" }}></i>}</td>

                                        <td>

                                            <Link to={`/dashboard/showTemplates/edite/${item.id}`} style={{ color: "#fff" }}><button className="btn btn-dark btn-sm mx-1">ویرایش</button></Link>
                                            <Link to={`/dashboard/showTemplates/${item.id}`} style={{ color: "#fff" }}><button className="btn btn-dark btn-sm mx-1">جزئیات</button></Link>
                                            <Link to={`/dashboard/showLandings/installLanding/${item.id}`}><button style={{ color: "#fff"}} className="btn btn-dark btn-sm mx-1">ایجاد لندینگ<i className="bi bi-plus me-1"></i></button></Link>
                                            <button className='btn btn-danger btn-sm mx-1' data-bs-toggle="modal" data-bs-target={`#iam${index}`}>حذف</button>


                                            <ModalYesorNo idModal={`iam${index}`} doBtn="حذف" text={`قالب ${item.title} حذف شود؟`} Click={() => deleteTemplateHandler(item.id)} />


                                        </td>

                                    </tr>
                                })}



                            </tbody>


                        </table>



                    </div>

                    
                    {length !== 3 && <MyPagination meta={metaPages} myPath={myPath} paginationHandler={paginationHandler} joinqueries={joinqueries}/>}



                </div>

            </>

            :

            <ShowNothing  text="قالبی نصب نشده است"/>

            :
            <Loading color="#7545fb" />
    );
};

export default ShowTemplates;