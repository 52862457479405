import React from 'react';

//styles
import styles from "./ForShowDash.module.scss"

const ForShowDash = () => {

    return (

        <>

            <div className={styles.dashContent}>

                <div>

                    <span>به داشبورد شخصی خود خوش آمدید</span>

                    <img src="https://cdn.hamkadeh.com/up-files/1685738734.png" alt="logoLandingMaker" className={styles.logoLandingMaker} />

                </div>


            </div>




        </>
    );
};

export default ForShowDash;