import React from 'react';
import styles from "./MyPagination.module.scss";
import { Link } from 'react-router-dom';

const MyPagination = ({ meta, myPath, paginationHandler, joinqueries }) => {
  const { current_page, last_page, links } = meta;

  const getPageUrl = (page) => {
    return `${myPath}?page=${page}${joinqueries !== "" ? `&${joinqueries}` : ""}`;
  };

  const renderPagination = () => {
    const pagination = [];
    const visiblePages = 3;
    const ellipsis = <span key="ellipsis" className={"page-link"}>...</span>;

    // Add previous page link
    if (current_page > 1) {
      pagination.push(
        <li   key="previous" className="page-item">
          <Link
            to={getPageUrl(current_page - 1)}
            onClick={() => paginationHandler(current_page - 1)}
            className={'page-link'}
          >
            &laquo; قبلی
          </Link>
        </li>
      );
    }

    // Add page links
    if (last_page <= visiblePages) {
      // If total pages is less than or equal to visiblePages, display all pages
      for (let i = 1; i <= last_page; i++) {
        pagination.push(
          <li  key={i} className={`page-item ${i === current_page ? "active" : ''} `}>
            <Link
              to={getPageUrl(i)}
              onClick={() => paginationHandler(i)}
              className='page-link'
            >
              {i}
            </Link>
          </li>
        );
      }
    } else {
      // If total pages is greater than visiblePages, add ellipsis and display appropriate page links
      let startPage, endPage;

      if (current_page <= visiblePages - 1) {
        startPage = 1;
        endPage = visiblePages;
      } else if (current_page >= last_page - 2) {
        startPage = last_page - (visiblePages - 1);
        endPage = last_page;
      } else {
        startPage = current_page - 1;
        endPage = current_page + 1;
      }

      if (startPage > 1) {
        pagination.push(
          <li key={1} className={`page-item ${1 === current_page ? "active" : ''}`}>
          <Link
            
            to={getPageUrl(1)}
            onClick={() => paginationHandler(1)}
            className='page-link'
          >
            1
          </Link>
          </li>
        );
        if (startPage > 2) {
          pagination.push(ellipsis);
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pagination.push(
          <li key={i} className={`page-item ${i === current_page ? "active" : ''} `}>
          <Link
            
            to={getPageUrl(i)}
            onClick={() => paginationHandler(i)}
            className='page-link'
          >
            {i}
          </Link>
          </li>
        );
      }

      if (endPage < last_page) {
        if (endPage < last_page - 1) {
          pagination.push(ellipsis);
        }
        pagination.push(
          <li key={last_page} className={`page-item ${last_page === current_page ? "active" : ''}` }>
          <Link
            
            to={getPageUrl(last_page)}
            onClick={() => paginationHandler(last_page)}
            className='page-link'
          >
            {last_page}
          </Link>
          </li>
        );
      }
    }

    // Add next page link
    if (current_page < last_page) {
      pagination.push(
        <li  key="next" className="page-item">
        <Link
         
          to={getPageUrl(current_page + 1)}
          onClick={() => paginationHandler(current_page + 1)}
          className={'page-link'}
        >
          بعدی &raquo;
        </Link>
        </li>
      );
    }

    return pagination;
  };

  return (
    <div className={styles.paginationDiv}>
      <nav aria-label="Page navigation example">
        <ul className="pagination">
          {renderPagination()}
        </ul>
      </nav>
    </div>
  );
};

export default MyPagination;
