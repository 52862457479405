import React, { useEffect, useState, useContext } from 'react';

//styles
import styles from "./EditeRole.module.scss";
import copyStyleOfNewuserModal from "../../shared/NewUserModal.module.scss";

//dependecyes
import { Select } from 'antd';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { notify } from '../../functions/toasts';


//contexts
import { myUserDataContext } from '../../App';

//shared
import Loading from '../../shared/Loading';
import { responseHandler } from '../../functions/responseHandler';


//functions
import { changeFaToEn } from '../../functions/changeFaToEn';




const EditeRole = () => {

    const navigate = useNavigate();
    const params = useParams();

    const [checkBtnEdite, setCheckBtnEdite] = useState(false);

    const data = useContext(myUserDataContext);
    const { userData, isLogin } = data;
    const { token } = userData;

    //a copy of role Data in database
    const [copyOfData, setCopyOfData] = useState({});

    const [editeRoleData, setEditeRoleData] = useState({});
    const [isLoad, setIsLoad] = useState(true);

    const [optionsPermissions, setOptionsPermissions] = useState([]);
    const { Option } = Select;


    useEffect(() => {

       
        const fetchData = async () => {
            try {
                const response1 = await axios.get(`/role/show/${params.id}?relations[]=permissions`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (response1.data.status) {

                    setEditeRoleData({ ...response1.data.data, permission_ids: response1.data.data.permissions.map(item => item.id) })
                    setCopyOfData({ ...response1.data.data, permission_ids: response1.data.data.permissions.map(item => item.id) })

                }

                const response2 = await axios.get("/permission/index", {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                responseHandler(response2, "succ", false);
                if (response2.data.status) {

                    setOptionsPermissions(response2.data.data)
                    setIsLoad(false);
                }
            } catch (error) {

                responseHandler(error, "error");
            }

        }


        fetchData();

    }, [])


    //for input of create role
    const inputHandler = (e) => {

        const first = changeFaToEn(e.target.value);
        setEditeRoleData({ ...editeRoleData, [e.target.name]: first })

    }

    //for selectHandler
    const handleChange = (e) => {

        setEditeRoleData({ ...editeRoleData, permission_ids: e })

    }



    //for compate two object
    const compareObjects = (a, b) => {

        return JSON.stringify(a) === JSON.stringify(b);
    };


    //for edite role
    const editeRoleHandler = () => {

        setCheckBtnEdite(true);

        //for don send request if data notchange
        let bodySend = {};

        for (let item in copyOfData) {

            if (item === "permission_ids") {

                if (compareObjects(copyOfData[item], editeRoleData[item]) === false) bodySend[item] = editeRoleData[item];
            }
            else {

                if (copyOfData[item] !== editeRoleData[item]) bodySend[item] = editeRoleData[item];
            }
        }

        const keysBodySend = Object.keys(bodySend);
        if (keysBodySend.length === 0) {

            notify("هیچ موردی را برای ویرایش تغییر نداید", "error", 2000);
            setCheckBtnEdite(false);
            return;

        }

        if(editeRoleData.name === ""){

            notify("نام نقش وارد نشده است" , "error");
            setCheckBtnEdite(false);
            return;
        }


       

        axios
            .put(`/role/update/${params.id}` , bodySend , {

                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(res=>{

                if(res.data.status){

                    responseHandler(res , "succ");
                    navigate("/dashboard/roles");
                    setCheckBtnEdite(false);
                }
            })
            .catch(error => {

                responseHandler(error , "error");
                setCheckBtnEdite(false);
            })



    }




    return (

        !isLoad && isLogin ?


            <div className={styles.DivEditeRole}>


                <div className={styles.ShowInfo}>

                    <img src="https://cdn.hamkadeh.com/up-files/1688047921.png" alt="rolepIC" />
                    <span>{copyOfData.name}</span>


                </div>




                <div className={copyStyleOfNewuserModal.Inputs}>
                    <label htmlFor='roleName'>نام نقش:</label>
                    <input type='text' id="roleName" name="name" value={editeRoleData.name} onChange={inputHandler} />
                </div>


                <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: "8px" }}>
                    <span>دسترسی ها:</span>
                </div>
                <Select

                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder={`بدون دسترسی`}
                    defaultValue={editeRoleData.permission_ids}
                    onChange={handleChange}
                    showSearch
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                // dropdownStyle={{ zIndex: 1060 }}
                >

                    {optionsPermissions.map((item, index) => <Option key={item.id} value={item.id}>{item.fa_name}</Option>)}
                </Select>


                <div className="mt-5">

                    <button className='btn btn-primary' disabled={checkBtnEdite} onClick={editeRoleHandler}>
                        ویرایش
                        {checkBtnEdite && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
                    </button>
                    <button className='btn btn-dark mx-1' onClick={() => navigate("/dashboard/roles")}>بازگشت به نقش ها</button>

                </div>



            </div>

            :
            <Loading color="#7545fb" />
    );
};

export default EditeRole;